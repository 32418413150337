#navigation {
	// background: @darkGray;
	position: relative;
	float: left;
	width: 100%;
    .colorMultifier(background, Primary);

	.clearfix();
	.center {
		padding: 0;
		background: none;
		ul {
			// .column(12);
			.clearfix();
			li {
				float: left;
				// margin: 15px;
				&:hover,
				&.active {
					a {
                        .colorMultifier(background, PrimaryDark);
					}
				}
				a {
					.border-radius(2px);
					padding: 20px 15px;
					display: inline-block;
					color: #fff;
					text-decoration: none;
//					text-transform: none;
					font-size: 15px;
				}
			}
		}
	}
}

#user-profile {
	position: relative;
	float: right;
	> a {
		display: block;
		width: 44px;
		float: left;
	}
	img {
		float: left;
		max-width: 44px;
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}
	.click-nav {
		ul {
			li {
				a {
					&.clicker {
						border-top-left-radius: 0px;
						border-bottom-left-radius: 0px;
					}
				}
			}
		}
	}
}

.click-nav {
	float: left;
	position: relative;
	z-index: 99;

	&.opened {
		z-index: 1001;
		ul {
			li {
				ul {
					z-index: 1002;
				}
			}
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			position: relative;
			cursor: pointer;
			a {
				display: block;
				padding: 8px 10px 8px 24px;
				background: white;
				color: #333;
				text-decoration: none;
				font-size: 13px;
				line-height: 18px;
				&.active {
					color: #333;
				}
				&:hover {
					background: #f5f5f5;
				}
				&:before {
					// .icon-before("\f105");
					.font-icon(@fa-var-angle-right);
					font-family: FontAwesome;
					margin-left: -13px;
					padding-right: 8px;
				}
				span {
					display: inline-block;
					font-size: 14px;
					color: #999;
				}
				&.clicker {
                    .colorMultifier(background, Primary);
					color: @white;
					.border-radius(2px);
					height: 44px;
					line-height: 44px;
					padding: 0 30px 0 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 15px;

					&.active {
						.border-bottom-radius(0px);
					}
					&.active,
					&:hover {
                        .colorMultifier(background, Primary50);
						color: white;
					}
					&:before {
						display: none;
					}
					.icon{

						position: absolute;
						top: 0;
						right: 18px;
						padding-left: 5px;
						font-size: 15px;
						color: @white;
						&:before {
							.font-icon(@fa-var-angle-down);
						}
					}
				}
			}
			ul {
				z-index: 100;
				position: absolute;
				top: 44px;
				left: auto;//For IE7 to make the menu position correctly
				right: 0;
				width: 100%;
				line-height: 1;
				.box-shadow(@shadow: 0 3px 5px rgba(0,0,0,.15));
				.border-bottom-radius(2px);
				li:last-child a {
					.border-bottom-radius(2px);
				}
			}
		}
	}
	.no-js {
		ul {
			display: none;
		}
		&:hover {
			ul {
				display: block;
			}
		}
	}

	&#user-nav {
		width: 46px;
		ul {
			li {
				a {
					&.clicker {
						.border-radius(2px);
						border-top-left-radius: 0px;
						border-bottom-left-radius: 0px;
						padding: 0 10px 0 10px;
						text-align: center;
					}
				}
				ul {
					z-index: 100;
					position: absolute;
					top: 48px;
					right: 0;
					width: 200px;
					left: inherit;
					overflow: hidden;
					.border-radius(2px);
				}
			}
		}
	}
}
.lang-selector {
	float: left;
	color: #fff;
	position: relative;
	background: #466ea5;
	.language {
		cursor: pointer;
		display: block;
		padding: 12px 10px 13px;
	}
	ul {
		ul {
			display: none;
			position: absolute;
			right: 0px;
			top: 45px;
			background: #fff;
			border: 1px solid #ccc;
			box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
			&:before {
				content: "";
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid #fff;
				position: absolute;
				top: -10px;
				right: 25px;
			}
			&:after {
				content: "";
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid #fff;
				position: absolute;
				top: -10px;
				right: 25px;
				border-bottom: 10px solid #000;
				z-index: -1;
			}
			li {
				a {
					padding: 5px 10px;
					display: block;
					white-space: nowrap;
					text-decoration: none;
					&:hover {
						background: #466ea5;
						color: #fff;
					}
				}
			}
		}
	}
}
.mobile-nav {
	display: none;
	float: none;
	.language {
		padding: 12px 10px 3px;
	}
	ul {
		ul {
			position: relative;
			top: auto;
			right: auto;
			background: #466ea5;
			border: 0;
			box-shadow: none;
			padding-left: 30px;
			li {
				float: none !important;
				a {
					background: #466ea5 !important;
					&:hover {
						background: #375681 !important;
					}
				}
			}
			&:before {
				display: none !important;
			}
			&:after {
				display: none !important;
			}
		}
	}
}
@media (max-width: 768px) {
	.lang-selector {
		display: none;
	}
	.mobile-nav {
		display: block;
	}
}
