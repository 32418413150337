.breadcrumbs {
	background: none;
	ul {
		display: block;
		margin: 20px 15px 0;
		width: 100%;
		.clearfix();
		li {
			float: left;
			margin-right: 0;
			a {
				padding: 0 15px 0 0;
				display: inline-block;
				zoom: 1;
				*display: inline;
				text-decoration: none;
				font-weight: 400;
				font-size: 14px;
				width: 100%;
				.box-sizing(border-box);
				.transition(all 0.2s);
				color: @leftColGray;
				position: relative;

				i {
					margin-left: 10px;
					margin-right: 0px;
					font-size: 10px;
				}
				&:hover {
					color: @experisPrimary;
				}
			}
			&.active {
				a {
					// background: @sapaTurquoise;
					// color: #fff;
					font-weight: bold;
					// &:hover {
					// 	.transition(all 0.2s);
					// 	background: #38c0c0;
					// }
				}
			}
		}
	}

}