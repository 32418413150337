.inlineblock{
	display: inline-block;
	*display: inline;
	zoom:1;
}

// Hover behaviour
.hoverUnderline(){

  text-decoration: none;

  &:hover,
  &:focus{

    text-decoration: underline;

    &:before{
      text-decoration: none;
    }

  }
}