﻿
.manpower.agreementpage {
    .btn.btn-primary.btn-accept-agreement {
        color:#fff;
    }
    .right-agreement {
        margin-top:30px;
        a:first-child {
            margin-right:20px;
            margin-bottom:0;
            @media screen and (max-width: 480px) {
                margin-bottom:30px;
            }
        }
    }
    .page-content {
        padding-bottom: 50px;
    }
}

