.related.inner-wrapper {

	padding-top: 1em;
	padding-bottom: 3em;
//	background: darken(@lightGray, 2%);
	color: @text-gray-dark;
	clear: both;
    max-width: 100%;

	.arrow-link-list{
		width: 100%;
        max-width: 600px;
		padding-right: 0;
		li{
			a{
				// color: white;
			}
		}
	}
	h2 {
		margin-top: 0;
	}
	h4{
		font-weight: bold;
	}
	.img {
		width: 20%;
		margin: 0 3% 0 0;
	}
	.bd {
		width: 77%;
		.arrow-link {
			// color: #fff;
		}
	}
}