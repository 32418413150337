.certificate-request {

  .inner-wrapper {
    &.error {
      background-color: #e77c22;
      color: #fff;
      max-width: none;
      padding: 8px 20px;
    }
    &.success {
      background-color: #ebebeb;
      max-width: none;
      padding: 8px 20px;
    }
  }

  .accordion-item .accordion-content {

    form {
      margin-top: 34px;

      input.datepicker {
        max-width: 12rem;
        margin-top: 8px;
        margin-bottom: 14px;
        cursor: pointer;
        background-color: #fff;
      }

      .checkbox-wrapper {
        margin-bottom: 2rem;
      }

      button {
        margin-bottom: 26px;
      }
    }

  }

}