#docs-controls {
	//background: @sapaWarmGrey;
	background: transparent;
	.clearfix();
	ul {
		float: left;
		.clearfix();

		li {
			float: left;
			margin-right: 0;
			a {
				padding: 18px 20px;
				display: block;
                .colorMultifier(color, Primary);
				text-decoration: none;
				font-size: 14px;
				font-weight: 600;
//				text-transform: none;
				i {
					margin-right: 5px;
				}
				&:hover {
                    .colorMultifier(background, Gray0);
                    .colorMultifier(color, Primary);
				}
			}
		}
	}
}