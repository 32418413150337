// --------------------------
// Conversion table for classes using legacy font-awesome3 => 4
// --------------------------
.icon-arrow-right{
	&:before{
		.font-icon(@fa-var-arrow-right);
	}
}
.icon-arrow-left{
	&:before{
		.font-icon(@fa-var-arrow-left);
	}
}
.icon-chevron-down{
	&:before{
		.font-icon(@fa-var-chevron-down);
	}
}
.icon-chevron-left{
	&:before{
		.font-icon(@fa-var-chevron-left);
	}
}
.icon-chevron-right{
	&:before{
		.font-icon(@fa-var-chevron-right);
	}
}
.icon-chevron-up{
	&:before{
		.font-icon(@fa-var-chevron-up);
	}
}
.icon-user{
	&:before{
		.font-icon(@fa-var-user);
	}
}
.icon-tag{
	&:before{
		.font-icon(@fa-var-tag);
	}
}
.icon-mobile-phone{
	&:before{
		.font-icon(@fa-var-mobile-phone);
	}
}
.icon-phone{
	&:before{
		.font-icon(@fa-var-phone);
	}
}
.icon-envelope{
	&:before{
		.font-icon(@fa-var-envelope);
	}
}
.icon-remove{
	&:before{
		.font-icon(@fa-var-times);
	}
}
.icon-angle-down{
	&:before{
		.font-icon(@fa-var-angle-down);
	}
}
.icon-search{
	&:before{
		.font-icon(@fa-var-search);
	}
}
.icon-edit{
	&:before{
		.font-icon(@fa-var-edit);
	}
}
.icon-time{
	&:before{
		.font-icon(@fa-var-clock-o);

	}
}
.icon-plus-sign{
	&:before{
		.font-icon(@fa-var-plus-circle);

	}
}
.icon-reorder{
	&:before{
		.font-icon(@fa-var-reorder);

	}
}
.icon-sign-out{
	&:before{
		.font-icon(@fa-var-sign-out);
	}
}
.icon-sign-in{
	&:before{
		.font-icon(@fa-var-sign-in);
	}
}
.icon-list-alt{
	&:before{
		.font-icon(@fa-var-list-alt);
	}
}
.icon-comment{
	&:before{
		.font-icon(@fa-var-comment);
	}
}
.icon-play{
	&:before{
		.font-icon(@fa-var-play);
	}
}
.icon-pause{
	&:before{
		.font-icon(@fa-var-pause);
	}
}
.icon-folder-close{
	&:before{
		.font-icon(@fa-var-folder);
	}
}
.icon-building{
	&:before{
		.font-icon(@fa-var-building);
	}
}
.icon-globe{
	&:before{
		.font-icon(@fa-var-globe);
	}
}
.icon-signin{
	&:before{
		.font-icon(@fa-var-sign-in);
	}
}
.icon-file{
	&:before{
		.font-icon(@fa-var-file-text);
	}
}
.icon-file-alt{
	&:before{
		.font-icon(@fa-var-file-text-o);
	}
}
.icon-copy{
	&:before{
		.font-icon(@fa-var-copy);
	}
}
.icon-bullhorn{
	&:before{
		.font-icon(@fa-var-bullhorn);
	}
}
.icon-calendar{
	&:before{
		.font-icon(@fa-var-calendar);
	}
}
.icon-share-square-o{
	&:before{
		.font-icon(@fa-var-share-square-o);
	}
}
.icon-check-circle{
	&:before{
		.font-icon(@fa-var-check-circle);
	}
}
.icon-times-circle{
	&:before{
		.font-icon(@fa-var-times-circle);
	}
}
.icon-lock{
	&:before{
		.font-icon(@fa-var-lock);
	}
}
.icon-link{
	&:before{
		.font-icon(@fa-var-link);
	}
}
.icon-sitemap{
	&:before{
		.font-icon(@fa-var-sitemap);
	}
}
.icon-home { 
    &:before{
		.font-icon(@fa-var-home);
	}
}
.icon-align-justify { 
    &:before{
		.font-icon(@fa-var-align-justify);
	}
}