.featured-news {
	padding: 1.4em @padding-xlarge 2em;

    .article-header h1, .article-header h2 {    
        margin-top:0.2em;   
        font-size:26px;
    }
	.featured-header {
		//background-color: #d5d5d5;
		color: #000;
		margin: 0 -20px -24px;
		padding: 0 20px;
		font-weight: normal;
		font-size: 15px;
		text-transform: none;
	}

	.teaser-col {
		position: relative;
		display: block;
		margin-bottom: 20px;
		.box-sizing(border-box);
        .categories {
            min-height:33px;
        }
		.categories, .tags {

			.clearfix();
			display: block;
			margin: 0;
			float: none;
			font-size: 1px; // remove whitespace

			> *,
			&:before {
				.inlineblock();
				float: right;
				position: relative;
				margin: 1px 0 0 1px;
				font-size: 11px;
				font-weight: 900;
				color: #fff;
				text-transform: none;
				text-decoration: none;
				background: @experisPrimary;
			}
			&:before {
				// pseudo element to keep the negatively positioned featured title away from tags, especially in smaller screens.
				content: " ";
				float: left;
				width: 100px;
				opacity: 0;
				background-color: transparent;
				// background-color: orange;
			}
		}
		.video {
			height: 300px;
			width: 100%;
		}
		> .video, > img {
			margin-bottom: 1em;
		}
		.inner-wrapper {
			padding: 0;
			position: relative;
			h1 {
				margin-bottom: 10px;
				a {
					font-weight: normal;
				}
			}
			.time {
				display: block;
				color: #a6a6a6;
				margin-bottom: 10px;
			}
			.icons {
				position: absolute;
				right: 20px;
				bottom: 20px;
				a {
					color: #a5a5a5;
					margin-left: 20px;
					float: left;
				}
			}
			p {
				margin-bottom: 20px;
			}
		}
	}
}