
	.tags {
		.clearfix();
		margin-bottom: 20px;
		width: 100%;
		p, a {
			padding: 7px 14px;
			float: left;
			margin: 0;
			text-decoration: none;
			color: #fff;
            .colorMultifier(background, Secondary);
			position: relative;
			.transition(all 0.2s);
		}
	}
	.btn {
		line-height: 14px;
		padding: 17px;
		display: inline-block;
		zoom: 1;
		*display: inline;
		color: #fff;
		text-decoration: none;
		font-weight: 900;
		width: auto;
		.box-sizing(border-box);
        .colorMultifier(background, Primary);
		.transition(all 0.2s);
		&.small {
			padding: 10px;
		}
		&:hover {
			.transition(all 0.2s);
            .colorMultifier(background, Primary75);
		}
		&.second {
            .colorMultifier(background, Secondary);
			&:hover {
                .colorMultifier(background, Secondary75);
			}
		}
		&.white {
            .colorMultifier(color, Primary);
			background: #fff;
			&:hover {
				color: #fff !important;
                .colorMultifier(background, Primary);
			}
		}
		&.cancel {
			color: #fff !important;
            .colorMultifier(background, Gray);
			.box-sizing(border-box);
			&:hover {
                .colorMultifier(background, Gray75);
			}
		}
		&.warning {
            .colorMultifier(background, Error);
			&:hover {
                .colorMultifier(background, Error75);
			}
		}
		&.active {
            .colorMultifier(background, PrimaryDark);
			cursor: default;
		}
	}
	.article-header{

		position: relative;

		h1, h2, h3, h4, h5{
			display: block;
			margin-bottom: 0.6em;
			line-height: @line-height-medium;

		}
		.time{
			display: block;
			font-size: @font-size-small;
			margin: 0 1em 0 0;
			padding-top: 0.7em;
		}
		.categories,
		.tags {
			display: block;
			position: relative;
			top: @padding-larger * -1;
			right: @padding-xlarge * -1;
			float: none;
			margin: 0;

			p {
				background-color: @experisPrimary;
				float: right;
				max-width: 80%;

			}
		}
	}
	.flex-caption{
		font-size: @font-size-small;
		line-height: @line-height-medium;
	}
	.categories {

		display: inline;
		float: right;
		white-space: nowrap;
		min-height: 25px;

		a,p {
			display: block;
			float: right;
			padding: 0.6em 14px; // A certain vertical padding allows titles wrap directly underneath the categories
			margin: 0 0 1px 1px;
			font-size: @font-size-small;
			color: #fff;
			text-align: center;
			text-decoration: none;
			background-color: @experisPrimary;
		}
	}

.btn-delete {


	display: block;
	float: right;
	margin-left: 1em;
	opacity: 0.5;
	text-decoration: none;
	// position: absolute;
	// top: 5px;
	// right: 5px;
	border: none;
	padding: 0;
    .colorMultifier(background, Error);
	text-align: center;

	&:hover {
		opacity: 1;
        .colorMultifier(background, Error75);
	}
	// &:before {
	// 	.icon-before("\f00d");
	// 	position: relative;
	// 	color: #fff;
	// }
	i {
		position: relative;
		color: white;
		&:before{
			margin-right: 0;
		}
	}
	span {
		display: none;
	}
}
.btn-delete-admin {
	@BTNWIDTH : 20px;
	width: @BTNWIDTH;
	height: @BTNWIDTH;
	.border-radius( @BTNWIDTH );
}

button[type="submit"],
input[type="submit"] {
	line-height: 14px;
	padding: 17px;
	display: inline-block;
	zoom: 1;
	*display: inline;
	color: #fff;
	text-decoration: none;
	font-weight: 900;
	width: auto;
	.box-sizing(border-box);
    .colorMultifier(background, Success);
	.transition(all 0.2s);
	border: none;
	font-weight: 900;
	font-size: 14px;
	cursor: pointer;
	&:hover {
		.transition(all 0.2s);
        .colorMultifier(background, Success75);
	}
}

input[type="checkbox"] {
	width: auto;
	height: auto;
	display: inline-block;
}

button::-moz-focus-inner {
	border: 0;
	padding: 0;
}
input[type="submit"]::-moz-focus-inner {
	border: 0;
	padding: 0;
	margin-top: -1px;
	margin-bottom: -1px;
}