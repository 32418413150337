.mainIntro {
	margin: 20px auto 0px;
	h2 {
		font-size: 30px;
		margin-bottom: 20px;
		margin-top: 0;
	}
	.arrow-link {
		display: block;
		margin-top: 20px;
		clear:both;
		color: @experisPrimary;
	}
	.hiddenText {
		display: none;
	}
	.media {
		.img {
			width: 22%;
			margin-right: 40px;
		}
	}
}