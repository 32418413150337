#loginControl,
#forgotPasswordControl,
#resetPasswordControl{
    width: 100%;
    max-width: 800px;
    margin: 10% auto 0;
}

.login,
.forgot-password,
.reset-password {

    header {
        background-color: @white;
        padding: 10px;
    }

    .login-content {
        .clearfix;
        .colorMultifier(background-color, Primary);
        padding: 40px;
        color: @white;

        @media screen and (max-width: 656px) {
            padding: 10px;
        }

        > * {
            float: left;
        }

        .login-text {
            width: 45%;
            margin: 0 5% 2em 0;
            a.forgotten {
                background-color:#375681;
            }
            @media screen and (max-width: 656px) {
                width: 100%;
            }
        }

        .login-form {
            width: 50%;

            .control-group {
                label {
                    display: block;
                    padding-bottom: 5px;
                }

                .controls {
                    margin-bottom: 10px;
                }
            }

            input[type="text"],
            input[type="password"]{
                width: 100%;
                .colorMultifier(background, Primary50);
                color: @white;
                border: none;
                padding: 15px 10px;
                font-size: 16px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }

            @media screen and (max-width: 656px) {
                width: 100%;
            }

            .alert {
                margin-bottom: 10px;
                padding: 10px;
                text-align: left;
            }

            .validation-message[style*="inline"] {
                display: block !important;
                padding: 3px;
                margin-bottom: 3px;
                .colorMultifier(color, Error);
                background: white;
            }
        }

        .form-actions {
            overflow:hidden;
            a {
                .colorMultifier(background-color, PrimaryDark);
                float:right;
                &.btn-login {
                    
                }
            }
            span {
                float:left;
            }
            label {
                position: relative;
                top: -2px;
                left: 3px;
            }
        }
        .agreement {
            max-height:0;
            overflow:hidden;
            * {
                box-sizing:border-box;
            }
            &.slide {
                transition:max-height 0.6s ease-in;
                max-height:800px;
            }
            .left-agreement, .right-agreement {
                float:left;
                width:50%;
                @media screen and (max-width: 656px){
                    width:100%;
                }
            }
            .left-agreement { 
                @media screen and (max-width: 656px){
                        margin-top:30px;
                    }                              
                > div {                    
                    border:1px solid #fff; 
                    margin-right:30px;
                    margin-bottom:0;
                    max-height:200px;
                    overflow:scroll;
                    overflow-x:auto;
                    padding:5px;
                    @media screen and (max-width: 656px){
                        margin-right:0;
                        margin-bottom:20px;
                    }
                }
            }
            .right-agreement {
                margin-bottom:0;
                min-height:200px;
                position:relative;
                @media screen and (max-width: 656px){                 
                    margin-bottom:20px;
                    min-height:0;
                }
                .btn-accept-agreement {
                    background-color:#375681;
                    position:absolute;
                    right:0;
                    bottom:0;
                    @media screen and (max-width: 656px){                 
                        position:static;
                    }
                }
            }
        }
    }
}

.reset-password {
    .login-text > a {
        .colorMultifier(background, PrimaryDark);
    }
}