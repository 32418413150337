/// <reference path="variables.less" />
/// <reference path="mixins.less" />
/// <reference path="grid.less" />
/// <reference path="bootstrap/variables.less" />
.info-message input,
textarea {
    &.placeholder {
        font-style: italic;
        color: #888;
    }
}

.form-success {
    padding: 30px;
    background-color: @sapaLightGreen;
    color: white;
    margin-bottom: 20px;

    p {
        margin-bottom: 0;
    }
}

.validation-summary-errors {
    padding: 30px;
    background-color: @sapaRed;
    color: white;

    ul {
        list-style-type: disc;
        margin: 0 0 0 20px;

        li {
            margin-bottom: 5px;
            background-color: transparent;
        }
    }
}

.xform {
    .clearfix();

    fieldset {
        .clearfix();
        padding: 30px;
        background: #f5f5f5;
        margin-bottom: 10px;

        fieldset {
            padding: 0;
            margin-bottom: 20px;
        }

        label {
            display: inline-block;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 14px;

            &.checkbox,
            &.radio {
                .clearfix();
                clear: both;
                float: left;
            }

            &.checkbox.horizontal,
            &.radio.horizontal {
                clear: none;
                padding-right: 1em;
            }
        }

        legend {
            .clearfix();
            display: block;
            width: 100%;
            padding: 0 0 5px 0;
            margin: 0;
            float: left;
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: bold;
            line-height: inherit;
            color: #111;
            border: 0;
            border-bottom: 2px solid #dfdfdf;
        }
    }

    .row {
        .clearfix();
        margin-bottom: 20px;

        .half {
            display: inline-block;
            float: left;
            width: 50%;

            &.last {
                width: 47%;
                margin-left: 3%;
            }
        }
    }

    p {
        .clearfix();
        margin-bottom: 20px;
    }

    .form-actions {
        .btn {
            float: right;
            margin: 2px;
        }
    }
}

// Use for all form elements
.form-control,
input[type="text"].form-control,
.ui-timepicker-select {
    .box-sizing(border-box);
    display: block;
    width: 100%;
    height: auto;
    min-height: 40px;
    padding: 10px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #444;
    vertical-align: middle;
    background-color: white;
    background-image: none;
    border: 2px solid #dfdfdf;
    border-radius: 0px;

    &:focus {
        .box-shadow(none);
        border-color: @mainTurkoise;
        outline: none;
        color: #444;
    }

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        cursor: not-allowed;
        background-color: #eee;
    }
    // Reset height for `textarea`s
    textarea& {
        height: auto;
    }

    input[type="text"]& {
        border-width: 2px;
    }

    &.error {
        border: 2px solid #f04131;
        color: #ef4030;
    }

    &.validation-error {
        border: 2px solid #f04131;
        color: #ef4030;
    }
}

.field-validation-error {
    display: none;
}

.xform-validation-error {
    .form-control,
    input[type="text"].form-control,
    .ui-timepicker-select {
        border: 2px solid #f04131;
        color: #ef4030;

        &:focus {
            .box-shadow(none);
            border-color: @mainTurkoise;
            outline: none;
            color: #444;
        }
    }

    label {
        color: #ef4030;
    }

    .field-validation-error {
        float: left;
        width: 100%;
        display: block;
        padding: 2px 0;
        margin-top: 2px;
        font-size: 14px;
        color: #ef4030;

        &:before {
            font-family: FontAwesome;
            content: "\f071";
            padding-right: 8px;
        }
    }
}

select[multiple],
select[size] {
    height: auto;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 2px 0 0;
    margin-top: 1px \9; /* IE8-9 */
    line-height: normal;
}

.pdfFormContainer select {
    background: #fff;
    border: 1px solid #000;
    border-radius: 2px;
    padding: 10px;
    font-size: 16px;
    width: 94%;
}

/* Karun Subcontractor Forms CSS */
.pdfFormContainer input[type=text],
.pdfFormContainer input[type=email] {
    width: 90%;
    border-color: #333;
}
.pdfFormContainer h3 {
    font-size: 13px;
    font-weight: bold;
}
.pdfFormContainer table td {
    vertical-align:top;
    padding: 5px !important;
}
.history {
    max-height: 100px;
    overflow: auto;
    width: 250px;
    border: 1px solid #000
}