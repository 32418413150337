#showLeftPush {
	// display: none;
	position: relative;
	border: none;
	text-align: center;
	z-index: 20;
}
#hideLeftPush {
	width: 100%;
	margin: 0;
	padding: 15px 0;
	position: relative;
	left: 0px;
	top: 0px;
	border: none;
	text-align: center;
	z-index: 20;
}
/* General styles for all menus */
.cbp-spmenu {
	// background: #47a3da;
	position: fixed !important;
	.left-col {
		margin-top: 43px;
	}
}


/* Orientation-dependent styles for the content of the menu */

.cbp-spmenu-vertical {
	width: 100% !important;
	height: 100%;
	top: 0;
	z-index: 1000;
	overflow: scroll;
}

.cbp-spmenu-horizontal {
	width: 100%;
	height: 150px;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

/* Vertical menu that slides from the left or right */

.cbp-spmenu-left {
	left: -100%;
}

.cbp-spmenu-right {
	right: -100%;
}

.cbp-spmenu-left.cbp-spmenu-open {
	left: 0px;
}

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0px;
}

/* Horizontal menu that slides from the top or bottom */

.cbp-spmenu-top {
	top: -150px;
}

.cbp-spmenu-bottom {
	bottom: -150px;
}

.cbp-spmenu-top.cbp-spmenu-open {
	top: 0px;
}

.cbp-spmenu-bottom.cbp-spmenu-open {
	bottom: 0px;
}

/* Push classes applied to the body */

.cbp-spmenu-push {
	overflow-x: hidden;
	position: relative;
	left: 0;
	.button-left {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 50px;
		background: #d5d5d5;
		#showLeftPush {
			width: 50px;
			padding: 15px 0;
		}
	}
	.featured-news {
		margin-left: 40px;
	}
}

.cbp-spmenu-push-toright {
	left: 100%;
	width: 0 !important;
	overflow: hidden !important;
}

.cbp-spmenu-push-toleft {
	left: -100%;
}

/* Transitions */

.cbp-spmenu,
.cbp-spmenu-push {
	-webkit-transition: left 0.3s ease;
	-moz-transition: left 0.3s ease;
	transition: left 0.3s ease;
}




/* Example media queries */

// @media screen and (max-width: 55.1875em){

// 	.cbp-spmenu-horizontal {
// 		font-size: 75%;
// 		height: 110px;
// 	}

// 	.cbp-spmenu-top {
// 		top: -110px;
// 	}

// 	.cbp-spmenu-bottom {
// 		bottom: -110px;
// 	}

// }

// @media screen and (max-height: 26.375em){

// 	.cbp-spmenu-vertical {
// 		font-size: 90%;
// 		width: 190px;
// 	}

// 	.cbp-spmenu-left,
// 	.cbp-spmenu-push-toleft {
// 		left: -190px;
// 	}

// 	.cbp-spmenu-right {
// 		right: -190px;
// 	}

// 	.cbp-spmenu-push-toright {
// 		left: 190px;
// 	}
// }