.alert {
	padding-bottom: 0;
	div	{
		.colorMultifier(background, Secondary);
		.clearfix();
		.box-sizing(border-box);
		width: 100%;
		float: none;
		display: block;
		padding: 15px 15px;
		color: @white;
		p {
			float: left;
			font-size: 14px;
			margin: 0;
			&.dismiss {
				float: right;
			}
		}
		a {
			font-style: italic;
			color: darken(@sapaOrange, 50%);
			i {
				text-decoration: none;
			}
		}
	}
	&.alert-warning {
		margin-top: 20px;
	}

	&.alert-error {
		color: #ab404b;
	}

	&.alert-success {
		color: @white;
		.colorMultifier(background, Success);
	}
}