.comments {
	background: #fff;
	.media {
		&:nth-child(odd) {
			background: #f8f8f8;
		}
		&.child-comment {
			padding-left: 100px;
		}
		.img {
			margin-right: 20px;
		}
		.bd {
			h5 {
				font-weight: 900;
				margin-bottom: 12px;
			}
			p {
				margin-bottom: 10px;
			}
			a {
				color: #848484;
				text-decoration: none;
				font-size: 14px;
			}
			.time {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}