/* ====== media ====== */
.media {
	// margin:10px;
	.clearfix();
	.bd {
		.clearfix();
//		overflow: hidden;
		zoom: 1;
		position: relative;
	}
	.img {
//		float:left;
//		margin-right: 10px;
        margin-bottom: 10px;
		img {
			display:block;
		}
	}
	.imgExt {
		float: right; 
		margin-left: 10px;
	}
	&.vote {
		.clearfix();
		clear: both;
		.column(12);
		background: @lightGray;
		padding: 0;
		margin: 0 1.2544802867383513% 30px;
		.border-radius(2px);
		position: relative;
		.img {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background: #d5d5d5;
			.border-radius(2px);
			width: 90px;
			height: 100%;
			color: #fff;
			font-size: 30px;
			text-align: center;
			span {
				display: block;
				margin-top: 20px;
			}
			a {
				color: #fff;
				font-size: 24px;
				text-decoration: none;
			}
		}
		.bd {
			margin-left: 100px;
			padding: 10px;
			.time {
				color: #7c7c7c;
				top: 10px;
				right: 10px;
			}
		}
		p {
			font-size: 14px;
			line-height: 20px;
		}
		h2 {
			a {
				
			}
		}
		h3 {
			font-size: 22px;
			margin-bottom: 12px;
		}
	}
}