// overiding some styles which didn't work with IE7
.flexslider{



	.flex-direction-nav a{

		top: 0;
		height: 100%;
		margin: 0;
		background-color: transparent;
		.opacity(0);
		.transition(opacity 350ms);

		i {
			@btn-direction-height: 50px;

			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 50px;
			margin-top: (@btn-direction-height / -2);
			line-height: @btn-direction-height;
			text-align: center;
			font-size: @font-size-small;

			background-color: white;

		}
	}
	&:hover .flex-direction-nav a:hover{
		opacity: 1;
	}
	.flex-disabled{
		// Remove this when the flex-slider bug is found. Currently flex-slider still creates a .flex-direction-nav element even though there is only one slide.
		// Didn't happen in previous version.
		display: none;
	}
}
.featured-news-carousel {

	//background-color: #333;
	//color: white;
	.box-sizing(border-box);
	min-height: 500px;// to avoid too large of a transition when the slides are loaded
	// padding: 1.4em @padding-xlarge 2em;

	&,
	.flexslider,
	.flex-viewport,
	.slides,
	.slides > li{
		height: 100%;
	}

	.flex-viewport{
		// z-index: 2; // To enable the .arrow-link to receive hover state.
 	}

	.featured-news
	{
		margin-bottom: 3em; // space for the link and slider controls to recide.
		padding-bottom: 0em;
		.teaser-col{
			position: static;
			.inner-wrapper{
				position: static;
				.arrow-link{
					//position: absolute;
					bottom: 1.4em;

                    i {
                        margin-right: 5px;
                    }
				}
			}
		}
	}



	.flexslider{

		vertical-align: top;
		.transition(border-color 350ms);

		&:hover{

		}
		.slides{
			font-size: 0;// getting rid of some whitespace…
			> * {
				font-size: @font-size-base;// returning removed font-size.
			}
			> li {
                height: 947px;
                overflow: auto;
				background-color: transparent;
			}
		}
	}

	.flex-control-nav{
		// measures to insure the controls don't overlap the "Read full story" link but still slign to the center.
		bottom: 16px;
		left: 50%;
		width: auto;
		text-align: left;

		li:first-child{
			margin-left: -50%;
		}

	}
	.flex-direction-nav{


		a {
			top: 0;
			bottom: 50px; // space for bottom controls
			height: auto;
			margin: 0;

			i{
				top: 39.5%;
				border: 1px solid #f1f1f1;
			}
			&:hover i {
				// background-color: @btn-active-bg;
			}

		}
		.flex-next{
			//right: 22px;
			i{
				border-right: none;
			}
		}
		.flex-prev{
			//left: 22px;
			i{
				border-left: none;
			}
		}
	}
	.flex-pauseplay{
		position: absolute;
		bottom: 0;
		right: 100px;
		width: 62px;
		line-height: 42px;
		text-align: center;
		z-index: 20;
		a {
			width: 100%;
			height: 100%;
			display: block;
			&:hover{
				color: @experisPrimary;
				cursor: pointer;
			}
		}
		.flex-pause{
			.icon-play{
				display: none;
			}
		}

		.flex-play{
			.icon-pause{
				display: none;
			}
		}
		// For IE7 compatibility
		i {
			@h: 56px;
			display: block;
			height: @h;
			line-height: @h;
		}
	}
	.flex-control-paging li a:hover{
		background-color: @experisPrimary;
	}
}