#dropzone {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	div {
	    position: absolute;
	    height: 200px;
	    width: 400px;
	    margin: -100px 0 0 -200px;
	    top: 50%;
	    left: 50%;
	    text-align: center;
	    color: #fff;
	    font-size: 16px;
	    font-weight: bold;
	}
}
body.hover {
	#dropzone {
		background: #000;
		// .opacity(70);
		display: block;
	}
}

#content {
	&.hover {
		background: #d1fcea;
		box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
    }
}

#docs-file-uploader {
	display: none;
	&.hover {
		background: #d1fcea;
		box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
    }
    #docs-controls { // Is this an error as this node doesn't seem to be nested in the above one? -LR
	    .clearfix();
	    margin-bottom: 10px;
    }
}
#file-error-message{
	padding: 0;
	height: 0;
	overflow: hidden;
	text-align: center;
	.transition(all 0.8s);

	&[aria-hidden='true']{
		padding: 0;
		height: 0;
		background-color: @gray-lightest;
		& > *{
			height: 0;
			padding: 0;
			margin: 0;
		}
	}
	&[aria-hidden='false']{
		padding: 1em @padding-xlarge;
		height: auto;
		& > *{
			height: auto;
		}
	}
}
.alert{
	color: white;
	background-color: @gray-lightest;
	text-align: center;
	overflow: hidden;
}
.alert.alert-danger{
	.colorMultifier(background, Error);
    color: @black;
}

// #file-error-messages{
// 	position: relative;

// 	&.alert{
// 	}
	// .btn-close{
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	width: 46px;
	// 	height: 46px;
	// 	line-height: 46px;
	// 	&:before{
	// 		.font-icon(@fa-var-times);
	// 	}
	// 	&:hover,
	// 	&:focus{
	// 		cursor: pointer;
	// 		background: lighten(@sapaRaspberryPink, 10%);
	// 	}
	// }
// }


#documents {
	padding-left: 10px;
	> h1 {
		margin: 0;
		padding: 20px;
	}
	.btn {
		padding: 13px 15px;
		font-weight: 600;
		vertical-align: top;
	}
	.alert {
		padding: 15px;
	}
}

#edit-file {
	h2 {
		padding: 20px;
		margin: -20px -20px 20px -20px;
		color: #222;
		background: lighten(@sapaWarmGrey, 42%);
	}
}

#upload-form,
#edit-file,
#edit-files,
#lock-file,
#delete-file {
	padding: 20px;
	#uploadedfiles {
		margin: 0;
		padding: 0;
		list-style-type: none;
		> li {
			display: block;
			padding: 2px 0;
			margin-bottom: 10px;
			border-top: 1px solid #ddd;
			&:first-child{
				border-top: none;
			}
			&.uploaderror {
				background: #fbe3e4;
				.validationMessage {
					display: block;
					overflow: hidden;
					color: #8a1f11;
					padding: 5px 0;
				}
			}
		}
	}
	.remove {
		float: right;
	}
	.file {
		.clearfix();
		padding-top: 5px;
		.file-type {
			float: left;
			width: 100px;
			overflow: hidden;
			max-height: 100px;
			margin-bottom: 10px;
			a {
				display: block;
				padding: 10px 0 0 0;
			}
			img {
				width: auto;
				max-width: 100%;
			}
		}
		.show-versions {
			color: @experisPrimary;
			font-weight: bold;
			text-decoration: none;
			margin: 10px 0;
			i {
				font-size: 12px;
				margin-left: 3px;
			}
		}
		.file-versions {
			padding-top: 10px;
		}
		.file-actions {
			display: block;
			margin-left: 120px;
			overflow: hidden;
			.form-fluid {
				width: 100%;
				float: left;
				overflow: hidden;
				.clearfix();
			}
			.row-fluid {
				width: 100%;
				float: left;
				.clearfix();
			}
		}
	}
	.file-list {
		margin: 10px 0 0 20px;
		li {
			margin-bottom: 2px;
			span[class^="file-type"] {
				display: block;
				line-height: 16px;
			}
		}
	}
	.file-info {
		p {
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
	.form-actions {
		border-top: none;
		margin: 0;
		padding-right: 0;
		padding-left: 0;
		padding-top: 20px;
		border-top: 1px solid #ddd;
		.clearfix();
		form {
			display: inline;
		}
		.btn,
		input,
		input[type="submit"] {
			margin: 0;
			border: 0;
			line-height: 16px;
			i {
				font-size: 15px;
				margin-right: 3px;
			}
		}
	}
}

#version-uploader {
	ul {
		list-style-type: none;
		padding: 0;
		margin: 5px 0 0 0;
		li {
			.name {
				display: block;
				background: #eee;
				padding: 2px 5px;
			}
		}
	}
}

.file-versions {
	table {
		text-align: left;
		width: 100%;
		font-size: 13px;
		thead {
			th {
				font-weight: 700;
				background: #ddd;
				padding: 10px;
				border-bottom: 1px solid #ddd;
			}
		}
		tbody {
			tr {
				td {
					padding: 10px;
					border-bottom: 1px solid #ddd;
				}
			}
			tr:nth-child(even) td {
				background: #f5f5f5;
			}
		}
	}
}

#document-table {
	clear: both;
	margin-top: 0;
	width: 100%;
	font-size: 14px;

	thead {
		tr {
			th {
                .colorMultifier(background, Gray);
				font-weight: bold;
				padding: 15px;
				text-align: left;
				cursor: default;
                color: @white;

                a {
                    color: @white;
                }
				&.min {
					cursor: default;
				}
				&.table-header-checkbox {
					// .border-top-left-radius(3px);
				}
				&.table-header-edit {
					// .border-top-right-radius(3px);
				}
				.sorting {
					display: block;
					text-decoration: none;
					&:after {
						color: @lightGray;
						padding-left: 7px;
						.icon-before("\f0dc");
					}
					&.sort-up {
						&:after {
							color: @white;
							.icon-before("\f0de");
						}
					}
					&.sort-down {
						&:after {
							color: @white;
							.icon-before("\f0dd");
						}
					}
				}
			}
		}
	}
	tbody {
		tr {
			&:nth-child(odd) {
				td {
					background: #f7f7f7;
				}
			}
			td {
				text-align: left;
				padding: 12px 15px;
				a {
					text-decoration: none;
				}
				.show-editform {
					visibility: hidden;
					display: block;
					padding: 8px;
					&:hover {
						text-decoration: none;
					}
				}
				&.table-body-file-name {
					a {
						display: block;
						//color: @sapaTurquoise;
					}
				}
				&.table-body-edit,
				&.table-body-versions {
					padding: 0;
					a {
						text-decoration: none;
					}
				}
				&.table-body-checkbox {
					cursor: pointer;
					input {
						border: none;
						outline: none;
						cursor: pointer;
					}
				}
				&.table-body-lock {
					padding: 8px 0;
				}
			}
			&.versions {
				td {
					.icon-dropdown {
						background: url("../images/icon-dropdown.png") no-repeat 4px 5px;
					}
				}
				&.expanded {
					td {
						.icon-dropdown {
							background-position: 4px -70px;
						}
					}
				}
			}
			&.checked {
				td {
					.colorMultifier(background, Edit);
				}
				&:nth-child(odd) {
					td {
                        .colorMultifier(background, EditOdd);
					}
				}
			}
		}
	}
}

.row-fluid {
	.clearfix();
	margin-bottom: 10px;
	input[type="text"] {
		.clearfix();
		width: 100%;
		.box-sizing(border-box);
		margin-top: 0;
		margin-bottom: 0;
	}
	.span8 {
		width: 50%;
		float: left;
		overflow: hidden;
		&:first-child {
			width: 48%;
			padding-right: 2%;
		}
		label {
			display: block;
			margin-bottom: 5px;
			color: #666;
			&.caption-checkbox {
				padding: 10px 0;
				margin-bottom: 0;
			}
		}
		.select-ajax,
		.select-multiple-ajax {
			width: 100%;
		}
	}
}


#docs-file-uploader {
    display: none;
}

.menu-edit-multiple.disabled {
    display: none;
}

.hide {
    display: none;
}

/* FILE TYPE ICON */
.table-body-file-name a { background: url('/Content/images/file-types/default.gif') left center no-repeat; padding-left: 25px; padding-bottom: 1px; padding-top:2px; }
.uri-type-url { background: url('/Content/images/file-types/link.png') left center no-repeat; padding-left: 23px; }
.file-type-bmp { background: url('/Content/images/file-types/bmp.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-doc { background: url('/Content/images/file-types/doc.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-docx { background: url('/Content/images/file-types/docx.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-gif { background: url('/Content/images/file-types/gif.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-htm { background: url('/Content/images/file-types/htm.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-html { background: url('/Content/images/file-types/html.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-jpg { background: url('/Content/images/file-types/jpg.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-jpeg { background: url('/Content/images/file-types/jpeg.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-mp3 { background: url('/Content/images/file-types/mp3.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-mpg { background: url('/Content/images/file-types/mpg.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-ppt { background: url('/Content/images/file-types/ppt.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-pptx { background: url('/Content/images/file-types/pptx.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-pdf { background: url('/Content/images/file-types/pdf.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-png { background: url('/Content/images/file-types/png.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-tif { background: url('/Content/images/file-types/tif.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-tiff { background: url('/Content/images/file-types/tiff.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-txt { background: url('/Content/images/file-types/txt.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-wav { background: url('/Content/images/file-types/wav.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-htm { background: url('/Content/images/file-types/htm.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-xls { background: url('/Content/images/file-types/xls.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-xlsx { background: url('/Content/images/file-types/xlsx.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-xlsm { background: url('/Content/images/file-types/xlsm.gif') left center no-repeat !important; padding-left: 23px; }
.file-type-zip { background: url(' /file-types/zip.gif') left center no-repeat !important; padding-left: 23px; }

@media screen and (max-width: 768px) {

	#upload-form,
	#edit-file,
	#edit-files,
	#lock-file,
	#delete-file {
		.file {
			.row(12);
			.file-type {
				.column(12);
				a {
					padding: 10px;
				}
			}
			.file-actions {
				margin-left: 0;
				.column(12);
				.form-fluid {
					.span8 {
						width: 100%;
						margin: 10px 0 0 0;

						.show-versions {
							float: none;
						}
					}
				}
			}
		}
		.form-actions {
			> div {
				width: 100%;
				.btn {
					margin-bottom: 10px;
				}
			}
		}
	}

}