aside {
	float: right;
	width: 25%;
	background: none !important;
	margin-bottom: 20px;
	h3 {
		font-size: @font-size-h5;
		text-transform: none;
		font-weight: 900;
		.hyphens();
	}
	.toggle-submenu {
		display: block;
		background: darken(@experisPrimary, 5%);
		color: #fff;
		position: relative;
		padding: 15px 15px;
		text-decoration: none;
		font-size: 12px;
		font-weight: bold;
		text-transform: none;
		display: none;
		i {
			float: right;
			margin-right: 10px;
		}
	}
	.group-header {
		background: darken(@experisPrimary, 5%);
		display: block;
		padding: 15px 15px 15px 15px;
		text-decoration: none;
		color: white;
		font-size: 18px;
		font-weight: 600;

		&:hover {
			background: lighten(@experisPrimary, 6%);
		}
	}
	.block {

		position: relative;
		overflow: hidden;
		padding: 15px;
//        text-transform: none;
        font-size: 14px;
		.colorMultifier(background, Primary75);
		.box-sizing( border-box);

		&.subscribe {
			@link-color: white;
			padding: 0;
			margin-bottom: @margin-bottom-loose;
			line-height: @line-height-medium;
			background-color: @sapaLightGreen;
			&:hover {
				background-color: lighten(@sapaLightGreen, 6%);
			}
			&.remove {
				background-color: @sapaRed;
				&:hover {
					background-color: lighten(@sapaRed, 6%);
				}
				.subscription-count {
					color: darken(@sapaRed, 25%);
				}
				a {
					&.disabled {
						.disable-link-oncolorbg(@link-color, @sapaRed);
					}
				}
			}
			.add {
				a {}
			}
			.remove {
				color: darken(@sapaRed, 25%);
				a {}
			}
			.subscription-count {
				margin: -10px 0;
				padding: 0 @padding-larger;
				color: darken(@sapaLightGreen, 25%);
			}
			.export {
				a {}
			}
			[class^="icon-"] {
				margin-right: 0.4em;
			}
			a {
				display: block;
				padding: @padding-larger;
				color: @link-color;
				text-decoration: none;
				&[href] {
					.hoverUnderline();
				}
				&.disabled {
					.disable-link-oncolorbg(@link-color, @sapaLightGreen);
				}
			}
		}
		&.links {
			width: 100%;
			padding: 0;
			margin-bottom: 20px;
			ul {
				@font-size-base-link-list: 14; // @font-size-base
				li {
					a {
						display: block;
						padding: 8px 1.7em 8px 8px;
						line-height: 18px;
						.border-radius(2px);
						font-size: unit( @font-size-base-link-list, px);
						text-decoration: none;
						color: #fff;
                        padding: 12px;
						&:hover {
							.colorMultifier(background, Primary);
						}
					}
					&.hasChildren {
						> a {
							position: relative;
							&:after {
								// .icon-before("\f107");//chevron-down?
								.font-icon(@fa-var-chevron-right);
								color: white;
								position: absolute;
								right: 8px;
								top: 50%;
								margin-top: -8px;
							}
						}
                        &.active {
                            >a:after {
                                .font-icon(@fa-var-chevron-down);
                            }
                        }
					}
					&.active {
						> a {
							// background: #eee;
                            .colorMultifier(background, Primary);
						}
						ul {
							padding: 0;
                            .colorMultifier(background, Primary);
							li {
								// margin: 7px 0;
								a {
									padding-left: 24px;
                                    &:hover {
                                        .colorMultifier(background, PrimaryDark);
                                    }
								}
								&.active {
									> a {
                                        .colorMultifier(background, PrimaryDark);
									}
								}
								ul {
									padding: 5px 0;
									li {
										a {
											padding-left: 40px;
											&:before {
												.font-icon(@fa-var-chevron-right);
												font-size: unit( @font-size-base-link-list - 2, px);
												margin-left: -12px;
												padding-right: 8px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.info-box {
			.colorMultifier(background, Primary);
			color: #fff;
			padding: 0.7em @padding-larger;
            text-transform: none;
			a {
				color: #fff;
				text-decoration: none;
			}
			&:nth-child(odd) {
                .colorMultifier(background, PrimaryDark);
			}
            *:last-child{
                margin-bottom: 0;
            }
			.readMore {
				color: white;
				font-weight: bold;
				text-decoration: none;
				padding-left: 0px;
				display: inline-block;
				font-size: 14px;
				font-weight: 700;
			}
		}
	}
}