#header {
    .top-bar {
        background: @white;
        color: #fff;
        padding: 15px 0 15px;
        width: 100%;
        position: relative;
        float: left;
        z-index: 80;
        .center {
            background: none;
            overflow: visible;
            padding: 0;
        }
        #logo {
            float: left;
            max-width: 170px;
            margin-left: 15px;
            img {
                width: auto;
            }
        }
        .search-btn {
            display: block;
            float: right;
            .border-radius(2px);
            height: 44px;
            line-height: 44px;
            padding: 0 20px;
            color: #fff;
            text-decoration: none;
            margin-left: 10px;
            .colorMultifier(background, Primary);

            &:before {
                .icon-before("\f002");
                padding-right: 7px;
            }
            &.opened {
                &:before {
                    .icon-before("\f057");
                }
            }
        }
        .toggle-intranet {
            width: auto;
            padding: 13px;
            position: absolute;
            right: 110px;
            top: 0;
            display: none;
        }
        .toggle-search {
            width: auto;
            padding: 13px;
            position: absolute;
            right: 65px;
            top: 0;
            display: none;
            .colorMultifier(background, Primary);
        }
        .toggle-navigation {
            padding: 13px;
            width: auto;
            margin-right: 15px;
            position: absolute;
            right: 0px;
            top: 0;
            display: none;
            .colorMultifier(background, Primary);
        }
    }
}
