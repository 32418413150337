.arrow-link-list {
	.clearfix();
	float: left;
	width: 100%;

	li {
		a {
			display: block;
			position: relative;
			font-size: 14px;
			line-height: 17px;
			font-weight: 900;
			text-decoration: none;
			.colorMultifier(color, Primary);
			padding: 15px 10px 15px 12px;
			.hoverUnderline;
            border-top: 1px solid @lightGray;

            i {
                color: @lightGray;
                font-size: 1em;
                margin-right: 0.3em;
            }

			&:hover {
                .colorMultifier(background, Gray0);

                i {
                    .colorMultifier(color, Primary);
                }
			}
		}

        &:last-child a {
            border-bottom: 1px solid @lightGray;
        }
	}
}