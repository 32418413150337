// TODO: exchange the below with italic nodes in the DOM for IE7 support
// @icon: fa-chevron-right
.icon-before(@icon: "\f054") {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    content: @icon;
}


.colorMultifier (@property, @color) {
    .manpower & {
        @value: 'manpower@{color}';
        @{property}: @@value;
    }

    .experis & {
        @value: 'experis@{color}';
        @{property}: @@value;
    }
}

.font-icon(@icon: @fa-var-caret-right) {
    .fa();
    content: @icon;
}

.opacity(@opacity: 0.5) {
    -moz-opacity: @opacity;
    -khtml-opacity: @opacity;
    -webkit-opacity: @opacity;
    opacity: @opacity;
    @opperc: @opacity * 100;
    -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
    filter: ~"alpha(opacity=@{opperc})";
}

// Border Radius
.border-radius(@radius: 5px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

// Border Top Radius
.border-top-radius(@radius: 5px) {
    -webkit-border-top-right-radius: @radius;
    -webkit-border-top-left-radius: @radius;
    -moz-border-top-right-radius: @radius;
    -moz-border-top-left-radius: @radius;
    border-top-right-radius: @radius;
    border-top-left-radius: @radius;
}

// Border Bottom Radius
.border-bottom-radius(@radius: 5px) {
    -webkit-border-bottom-right-radius: @radius;
    -webkit-border-bottom-left-radius: @radius;
    -moz-border-bottom-right-radius: @radius;
    -moz-border-bottom-left-radius: @radius;
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}

// Drop shadows
.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.25)) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

// Box sizing
.box-sizing(@boxmodel) {
    -webkit-box-sizing: @boxmodel;
    -moz-box-sizing: @boxmodel;
    box-sizing: @boxmodel;
}

// Transitions
.transition(@transition) {
    -webkit-transition: @transition;
    -moz-transition: @transition;
    -ms-transition: @transition;
    -o-transition: @transition;
    transition: @transition;
}

// Transformations
.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
    -moz-transform: rotate(@degrees);
    -ms-transform: rotate(@degrees);
    -o-transform: rotate(@degrees);
    transform: rotate(@degrees);
}

.scale(@ratio) {
    -webkit-transform: scale(@ratio);
    -moz-transform: scale(@ratio);
    -ms-transform: scale(@ratio);
    -o-transform: scale(@ratio);
    transform: scale(@ratio);
}

.translate(@x: 0, @y: 0) {
    -webkit-transform: translate(@x, @y);
    -moz-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y);
    -o-transform: translate(@x, @y);
    transform: translate(@x, @y);
}

.skew(@x: 0, @y: 0) {
    -webkit-transform: skew(@x, @y);
    -moz-transform: skew(@x, @y);
    -ms-transform: skew(@x, @y);
    -o-transform: skew(@x, @y);
    transform: skew(@x, @y);
}

.translate3d(@x: 0, @y: 0, @z: 0) {
    -webkit-transform: translate3d(@x, @y, @z);
    -moz-transform: translate3d(@x, @y, @z);
    -ms-transform: translate3d(@x, @y, @z);
    -o-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}

// Text-transformations
.hyphens(@h: auto) {
    -webkit-hyphens: @h;
    -moz-hyphens: @h;
    -ms-hyphens: @h;
    hyphens: @h;
}

// Add an alphatransparency value to any background or border color (via Elyse Holladay)
#translucent {
    .background(@color: @white, @alpha: 1) {
        background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
    }

    .border(@color: @white, @alpha: 1) {
        border-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
        .background-clip(padding-box);
    }
}

// Gradient Bar Colors for buttons and alerts
.gradientBar(@primaryColor, @secondaryColor) {
    #gradient > .vertical(@primaryColor, @secondaryColor);
    border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
    border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}

// Gradients
#gradient {
    .horizontal(@startColor: #555, @endColor: #333) {
        background-color: @endColor;
        background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
        background-image: -ms-linear-gradient(left, @startColor, @endColor); // IE10
        background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
        background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
        background-image: linear-gradient(left, @startColor, @endColor); // Le standard
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",@startColor,@endColor)); // IE9 and down
    }

    .vertical(@startColor: #555, @endColor: #333) {
        background-color: mix(@startColor, @endColor, 60%);
        background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
        background-image: -ms-linear-gradient(top, @startColor, @endColor); // IE10
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
        background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
        background-image: linear-gradient(top, @startColor, @endColor); // The standard
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down
    }

    .directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
        background-color: @endColor;
        background-repeat: repeat-x;
        background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
        background-image: -ms-linear-gradient(@deg, @startColor, @endColor); // IE10
        background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
        background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
        background-image: linear-gradient(@deg, @startColor, @endColor); // The standard
    }

    .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
        background-color: mix(@midColor, @endColor, 80%);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
        background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
        background-image: -ms-linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down, gets no color-stop at all for proper fallback
    }

    .radial(@innerColor: #555, @outerColor: #333) {
        background-color: @outerColor;
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@innerColor), to(@outerColor));
        background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
        background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
        background-image: -ms-radial-gradient(circle, @innerColor, @outerColor);
        background-repeat: no-repeat;
        // Opera cannot do radial gradients yet
    }

    .striped(@color, @angle: -45deg) {
        background-color: @color;
        background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
        background-image: -webkit-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
        background-image: -moz-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
        background-image: -ms-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
        background-image: -o-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
        background-image: linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open .dropdown-toggle& {
        color: @color;
        background-color: darken(@background, 8%);
        border-color: darken(@border, 12%);
    }

    &:active,
    &.active,
    .open .dropdown-toggle& {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: @background;
            border-color: @border;
        }
    }

    .badge {
        color: @background;
        background-color: @color;
    }
}

// Link variants
// -------------------------
.disable-link-oncolorbg(@color: black, @bgcolor: white) {
    color: mix(@color, @bgcolor, 60%);
}

.readMoreLink() {
    display: inline-block;
    position: relative; // Contain the absolutely positioned icon
    margin: 0 0 0 1em; // Create space for the icon to occupy
    color: @experisPrimary;
    text-decoration: none;
    .hoverUnderline();
    // This class is set by the user in the editor and therefore a typical <i></i> node cannot be used to show the icon
    &:before {
        .icon-before();
        .link-icon();
        position: absolute; // Take the icon out of the flow to avoid it receiving an underline when hovered over.
        left: -1em;
        top: 0;
        height: 100%;
        line-height: 1.8em;
    }
}

.dynamic-boxes .lime.has-image .inner-wrapper {
    padding-top: 0px;
    /*margin: auto;*/
}
