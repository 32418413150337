&.search-opened {
    #header {
        .translate3d(0,0,0);
        #search {
            margin-top: 0px;
            overflow: visible;
        }
        #search-results {
            display: block;
        }
    }
    .center {
        .translate3d(0,0,0);
    }
}

#header {
    position: relative;
    z-index: 1000;

    #search {
        z-index: 999;
        position: relative;
        width: 450px;
        float: right;
//        margin-right: 15px;

        .search-wrapper {
            width: 100%;
            max-width: 1144px;
            margin: 0 auto;
            position: relative;
            clear: both;
            .clearfix();

            input {
                border: none;
                box-shadow: none;
                font-size: 14px;
                color: #222;
                padding: 5px 57px 5px 12px;
                line-height: 35px;
                height: 45px;
                float: right;
                margin: 0;
                .border-radius(2px);
                .transition(all 0.2s);
                background: @lightBgColor;
                width: 100%;
                .box-sizing(border-box);
                outline: none;
                &::-webkit-input-placeholder {
                    font-style: italic;
                    color: #888;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    font-style: italic;
                    color: #888;
                }
                &::-moz-placeholder {  /* Firefox 19+ */
                    font-style: italic;
                    color: #888;
                }
                &:-ms-input-placeholder {
                    font-style: italic;
                    color: #888;
                }
                &.placeholder{
                    font-style: italic;
                    color: #888;
                }
            }
            .search-submit {
                top: 0;
                right: 0;
                position: absolute;
                display: block;
                padding: 0;
                height: 45px;
                width: 45px;
                text-align: center;
                color: white;
                text-decoration: none;
                .colorMultifier(background, Primary);
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                &:hover {
                    .colorMultifier(background, Primary50);
                }
                i {
                    font-size: 20px;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                }
            }
        }
    }
}
#search-results {
    z-index: 1000;
    display: none;
    width: 100%;
    position: absolute;
    top: 55px;
    // left: 3%;
    right: 0px;
    background: #fff;
    .box-shadow(0px 3px 7px rgba(0,0,0,.15));
    z-index: 100;
    .box-sizing(border-box);
    .border-radius(2px);
    overflow: hidden;
    .transition(padding-top 0.2s linear);

    &.loading {
        padding-top: 40px;
        background-image: url('/Content/images/loader.gif');
        background-repeat: no-repeat;
        background-position: center 15px;
    }

    #circuit-search-result {
        .group {
            .heading {
                .clearfix();
                    display: block;
                    width: 100%;
                    padding: 20px;
                    .box-sizing(border-box);
                h3 {
                    color: #000;
                    text-transform: none;
                    font-size: 14px;
                    font-weight: 900;
                    float: left;
                    width: 55%;
                    margin-bottom: 0;
                }
                a {
                    float: right;
                    text-align: right;
                    &.filter {
                        .colorMultifier(color, Black);
                        font-size: 15px;
                        text-decoration: none;
                        &:before {
                            .icon-before("\f054");
                            padding-right: 5px;
                        }

                    }
                }
            }
            &+.group {
                .heading {
                    border-top: 1px solid;
                    .colorMultifier(border-top-color, Gray0);
                }
            }
            >ul {
                //padding: 10px;
                li {
                    .clearfix();
                    // margin-bottom: 15px;
                    .box-sizing(border-box);
                    &.even {
                        background-color: @lightBgColor;
                    }
                    &.itemhover {
                        background-color: @lightBgColor;
                    }
                    a,
                    .media {
                        text-decoration: none;
                        font-size: 14px;
                        .clearfix();
                        display: block;
                        padding: 10px 20px;
                        h4 {
                            .colorMultifier(color, Secondary);
                            font-weight: 900;
                            margin-bottom: 5px;
                        }
                        p {
                            .colorMultifier(color, Gray50);
                            font-size: 14px;
                            line-height: 18px;
                            margin-bottom: 0;
                        }
                        span {
                            font-size: 14px;
                            color: #aaa;

                        }
                        .img {
                            width: auto;
                            max-width: 70px;
                        }
                        a {
                            padding: 0;
                            .colorMultifier(color, Primary);
                            &:hover {
                                background: none;
                                color: #555;
                            }
                        }
                    }
                    &.type-user {
                        a {
                            &.bd {
                                p {
                                    .colorMultifier(color, Gray25);
                                }
                            }
                        }
                        .bd {
                            h4,
                            p {
                                margin-left: 85px;
                            }
                            h4 {
                                padding-top: 3px;
                            }
                        }
                    }
                    &.type-page {
                        .media {
                            .bd {
                                h4 {
                                    margin-left: 1.5em;
                                    &:before {
                                        .icon-before("\f0f6");
                                        padding-right: 10px;
                                        margin-left: -1.5em;
                                    }
                                }
                            }
                        }
                    }
                    &.type-club {
                        .bd {
                            h4,
                            p {
                                margin-left: 85px;
                            }
                            h4 {
                                font-weight: bold;
                                padding-top: 3px;
                            }
                        }
                    }
                    a {
                        &.media {
                            h4 {
                                font-weight: 400;
                            }
                        }
                        &:hover {
                            background-color: @lightBgColor;
                        }
                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }
                    p {
                        &.has-icon {
                            display: block;
                            margin-bottom: 10px;
                            font-weight: normal;
                        }
                        .extension{
                            font-size: @font-size-base - 2;

                        }
                    }
                    .two-columns {
                        word-break: break-all;
                        .clearfix();
                        &.media {
                            .bd {
                                display: block;
                                float: left;
                                width: 40%;
                                margin-left: 0;
                                p {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        > div {
                            display: block;
                            float: left;
                            margin: 0 0 0 5%;
                            width: 55%;
                            p {
                                margin-bottom: 3px;
                            }
                        }
                    }
                }
            }
            &.no-results {
                font-size: 16px;
                padding: 40px 20px;
                .colorMultifier(color, Gray0);
                text-align: center;
            }
            &.show-all {
                width: 100%;
                text-align: center;
                .box-sizing(border-box);
                .btn {
                    display: block;
                    font-weight: 100;
                }
            }
        }
    }
}