// dynamic boxes

.row {
//	&.turquoise {
	&.primaryColor {
        .colorMultifier(background, PrimaryLight);
		.content {
			margin-left: 0;
            .colorMultifier(background, Primary);

			padding-left: 15%;
			.dynamic-boxes {
				> li {
                    .colorMultifier(background, PrimaryLight);
					color: #fff;
					&:nth-child(even) {
                        .colorMultifier(background, Primary);
					}
				}
			}
		}
	}
//	&.orange {
    &.secondaryColor {
        background: @white;
		.content {
			margin-left: 0;
            .colorMultifier(background, Primary);
			padding-left: 15%;
			.dynamic-boxes {
				> li {
					background: @white;
                    .colorMultifier(color, Primary);
					&:nth-child(even) {
						.colorMultifier(background, Gray0);
					}
				}
			}
		}
	}
	&.none {
        .colorMultifier(background, PrimaryLight);
		.content {
			margin-left: 0;
			padding-left: 15%;
		}
	}
}

.dynamic-boxes {
	display: table;
	table-layout: fixed;
	position: relative; // For the absolutely positioned bottom links
	width: 100%;

	&.boxes-1 {
		>li{
			width: 100%;
		}
	}
	&.boxes-2 {
		>li{
			width: 50%;
		}
	}
	&.boxes-3,
	&.boxes-5,
	&.boxes-6 {
		>li{
			width: 33.33333%;
		}
	}
	&.boxes-4,
	&.boxes-7,
	&.boxes-8 {
		>li{
			width: 25%;
		}
	}

	> li {
		position: relative;
		display: table-cell;
		.box-sizing(border-box);
		background: #fff;
		vertical-align: top;

		article	{
			padding: 20px;
			.box-sizing(border-box);
			height: 100%;
			.clearfix();
			&.has-image {
				padding: 0;
			}
			.time {
				display: block;
				overflow: hidden;
				margin-bottom: 15px;
			}
			h1 {
				font-size: @font-size-h1 - 2;
				margin: 0 0 15px 0;
				font-weight: 600;
			}
			h2 {
				font-size: @font-size-h3 + 2;
				margin: 0 0 15px 0;
				font-weight: normal;
			}
			h3 {
				font-size: @font-size-h3 - 2;
				font-weight: normal;
				a{
					.hoverUnderline();
				}
			}
			p {
                font-family: @fontLight;
				margin-bottom: 1em; // The absolutely positioned link leaves an empty <p> which takes up space.
				font-size: @font-size-base;
			}
			*:last-child{
				margin-bottom: 0;
			}
			a {
				text-decoration: none;
				display: inline-block;
			}
			.media {
				padding-top: 10px;
				overflow: hidden;
			}
			.bd {
				font-style: italic;
				p {
					font-size: 14px;
					margin-bottom: 0;
				}
			}
			.readMore-wrapper{
				margin-bottom: 1em;
			}
			.readMore {
				line-height: @line-height-smaller;
				margin-bottom: 0.4em;
				&:before{
					line-height: 1.4em;
				}
			}

            .primaryColor & {
//                img {
//                    padding: 1.5em 0 0 1.5em
//                }
                a {
                    color: #fff;
                    text-decoration: none;
                    display: inline-block;
                }
            }

            .secondaryColor & {
                h3 {
                    .colorMultifier(color, Primary);
                }
                a {
                    display: block;
                    color: @black;
                }
            }
		}
	}
	.inner-wrapper{
		padding-top: 1.5em;
		padding-bottom: 1.5em;
        width:75%;
	}
	.three-cols {
		@margin-gutter: 1.25448%;
		padding-right: 0;
		padding-left: unit(@padding-xlarge / 970 * 100, %) - @margin-gutter;

		h2 {
			font-size: 24px;
			margin-left: @margin-gutter;
		}
		h3 {
			font-size: 14px;
			font-weight: 900;
			text-transform: none;
		}
		article {
			.column(4);
			line-height: @line-height-base;
			vertical-align: top;
			.box-sizing(border-box);
			padding: 0;
			.arrow-link-list {
				width: 100%;
				li {
					display: block;
					width: 100%;
					a {
						font-weight: 900;
                        display: block;
                        .colorMultifier(color, Primary);
					}
				}
			}
		}
	}
}