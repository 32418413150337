// // Normal
// @font-face{
//  font-family: 'Open Sans';
//     src: url(/Content/fonts/OpenSans/OpenSans-Regular.ttf);
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face{
//  font-family: 'Open Sans';
//     src: url(/Content/fonts/OpenSans/OpenSans-Italic.ttf);
//     font-weight: normal;
//     font-style: italic;
// }
// // Light
// @font-face{
//     font-family: 'Open Sans';
//     src: url(/Content/fonts/OpenSans/OpenSans-Light.ttf);
//     font-weight: thin;
//     font-style: normal;
// }
// @font-face{
//     font-family: 'Open Sans';
//     src: url(/Content/fonts/OpenSans/OpenSans-LightItalic.ttf);
//     font-weight: thin;
//     font-style: italic;
// }
// // Bold
// @font-face{
//     font-family: 'Open Sans';
//     src: url(/Content/fonts/OpenSans/OpenSans-Bold.ttf);
//     font-weight: bold;
//     font-style: normal;
// }
// @font-face{
//     font-family: 'Open Sans';
//     src: url(/Content/fonts/OpenSans/OpenSans-BoldItalic.ttf);
//     font-weight: bold;
//     font-style: italic;
// }


// // Normal
// @font-face{
// 	font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/segoeui.ttf);
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face{
// 	font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/segoeuii.ttf);
//     font-weight: 400;
//     font-style: italic;
// }
// // Light
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/segoeuil.ttf);
//     font-weight: 200;
//     font-style: normal;
// }
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/seguili.ttf);
//     font-weight: 200;
//     font-style: italic;
// }
// // Semi Light
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/segoeuisl.ttf);
//     font-weight: 300;
//     font-style: normal;
// }
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/seguisli.ttf);
//     font-weight: 300;
//     font-style: italic;
// }
// // Semi Bold
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/seguisb.ttf);
//     font-weight: 700;
//     font-style: normal;
// }
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/seguisbi.ttf);
//     font-weight: 700;
//     font-style: italic;
// }
// // Bold
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/segoeuib.ttf);
//     font-weight: 900;
//     font-style: normal;
// }
// @font-face{
//     font-family: 'SegoeUI';
//     src: url(/Content/fonts/segoeui/seguisbi.ttf);
//     font-weight: 900;
//     font-style: italic;
// }


@font-face {
    font-family: 'Inter';
    src: url(/Content/fonts/inter/Inter-VariableFont_slnt,wght.ttf);
    font-weight: normal bold 100 900;
    font-style: normal italic;
}


blockquote {
    font-family: Georgia, serif;
    font-size: 18px;
    line-height: 26px;
    color: #6b6b6b;
    margin: 15px 0;
    padding-left: 40px;
    background: url(/Content/images/quotemark.png) no-repeat left top;
    font-style: italic;
}


img {
    max-width: 100%;
    vertical-align: top;
}


h1  {
    font-size: @font-size-h1;
}
h2 {
    font-size: @font-size-h2;
//    text-transform: none;
}
h3 {
    font-size: @font-size-h3;
}
h4 {
    font-size: @font-size-h4;
}
h1, h2, h3, h4, h5, h6 {
    font-family: @fontRegular;
    line-height: @line-height-base;
    margin-bottom: unit( @margin-bottom-header, em);
}


p {
    font-size: @font-size-base;
    line-height: @line-height-base;
    margin-bottom: 15px;
}
a {
    color: #000;
    &.arrow-link {
        font-size: 14px;
        font-weight: 900;
        .colorMultifier(color, Primary);
        .hoverUnderline;
    }
    // :visited{
    //     color: @gray-light;
    // }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a{
    color: @btn-default-color;
    text-decoration: none;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h5 a:focus,
h6 a:focus{
    .colorMultifier(color, Primary);
}
strong {
    font-weight: 900;
}
.preamble {
    font-weight: 900;
}