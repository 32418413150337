.left-col {
	width: 50%;
    .colorMultifier(background, PrimaryLight);
	.box-sizing(border-box);
	z-index: 10;

	&.filter {
		width: 30%;
        padding-top: 50px;
	}
	&.narrow {
		position: absolute;
		width: 3%;
        .colorMultifier(background, Gray25);
	}
	li a{
		overflow-x: hidden;
		text-overflow:ellipsis;
	}
}
