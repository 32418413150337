.page-content,
.page-content-form {

	position: relative;
	float: left;
	// width: 72%;
	width: 97%;
	margin: 0 0 0 3%;
	padding-top: 1.2em;
	// padding-bottom: 3em;
	background: #fff;
	.clearfix();

	&.full-width {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 0;
	}
	em {
		font-style: italic;
	}
	b {
		font-weight: 700;
	}
	p {
		margin-bottom: 0.6em;
		position: relative;
		&.preamble {
			margin-bottom: 20px;
		}
		img{
			margin-bottom: 1em;
		}
	}
	// h1 {
	// 	font-size: 26px;
	// 	margin: 15px 0;
	// }
	// h2 {
	// 	font-size: 22px;
	// 	margin: 0 0 10px 0;
	// }
	// h3 {
	// 	font-weight: 600;
	// 	font-size: 16px;
	// 	margin: 0 0 10px 0;
	// }
	// h4 {
	// 	font-weight: 700;
	// 	font-size: 14px;
	// 	margin: 0 0 10px 0;
	// }
	.flexslider {

		margin-bottom: @margin-bottom-loose;

		.video {
			height: 300px;
			width: 100%;
		}
	}
	.inner-wrapper{
		float: none;
        max-width: 600px;

        &.details {
            max-width: 100%;
        }
	}
	.level-links {
		border-top: 2px solid @leftColGray;
		padding-top: 20px;
		margin-top: 20px;
		>h1 {
			padding-left: 20px;
		}
        >div {
            font-size: 0;
        }

		.inner-wrapper {
			padding-bottom: 0.8em;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            width: 50%;
            margin-bottom: 50px;

			h1, h2, h3, h4, h5, h5{
				line-height: @line-height-small;
			}

			&.media {
				.img {
					width: 100%;
					position: relative;
				}
			}
			.bd {
				h2 {
					margin-top: 0;
					a {
						text-decoration: none;
						color: @gray-dark;
						// color: @sapaTurquoise;
						// font-weight: bold;
						.hoverUnderline();
					}
				}

                p {
                    margin-bottom: 20px;
                }
			}
		}
	}
	.inner-wrapper {
		&.no-summary {
			padding-bottom: 0;
			h1 {
				// margin-bottom: 0; Legacy. -LR
			}
		}
		&.gray-box {
//			background: #e4e4e4;
			font-size: 14px;
			margin-bottom: 1em;
            max-width: 100%;
			&.summary {
				padding: 10px 20px;
				ul {
					.clearfix();
					list-style: disc;
					padding-left: 20px;
					li {
						float: left;
						margin: 4px 50px 4px 0;
					}
				}
			}
		}
		.align-center {
			margin: 0 auto;
			width: 300px;
			text-align: center;
			display: block;
		}
	}
}