
/* BREAKPOINTS */

body:after { display: none; content: 'breakpoint_1'; }
@media all and (min-width: 480px) {
	body:after { display: none; content: 'breakpoint_2'; }
}

@media screen and (max-width: 1000px) {
	body {
		#header {
			#search {
				width: 340px;
			}
			#user-profile {
				margin-right: 15px;
			}
		}
		.editor{
			overflow: hidden;
			table{
				table-layout: fixed; // Will keep the table constrained to given width. Isn't applicable for browsers with hyphenation capabilities.
			}
		}
	}
}

@media screen and (max-width: 900px) {
	body {
		header {
			.top-bar {
				#logo {
					margin-left: 15px;
				}
				#search {
					margin-right: 15px;
					display: block;
					&.open {
						display: block;
					}
				}
			}
		}

		#header {
			.top-bar {
				.center {
					#logo {
						min-height: 43px;
					}
				}
				#search {
					display: none;
					clear: both;
					position: relative;
					width: 100%;
					margin: 0;
					background: transparent;
					.search-wrapper {
						width: auto;
						padding: 15px 15px 0 15px;
						.search-submit {
							top: 15px;
							right: 15px;
						}
					}
					&.open {
						display: block;
					}
				}
				#search-results {
					top: 114px;
					left: 15px;
					right: 15px;
					width: auto;
				}
				.toggle-search {
					display: block;
				}

				#user-profile {
					position: absolute;
					right: 113px;
					top: 0;
					margin-right: 0;
					a {
						height: 41px;
						/*width: 43px;*/
						overflow: hidden;
						.border-radius(2px);
						img {
							max-width: 43px;
						}
					}
					#user-nav {
						//display: none;
                        ul {
                            ul {
                                a {
                                    height: auto;
                                }
                            }
                        }
					}
				}
				.search-btn {
					position: absolute;
					right: 62px;
					top: 0;
					padding: 13px;
					height: auto;
					line-height: 1;
					background: darken(@experisPrimary, 5%);
					&:before {
						padding: 0;
					}
					&.opened {
						&:before {
							.icon-before("\f002");
						}
					}
					span {
						//display: none;
					}
				}
				.toggle-navigation {
					display: block;
					.border-radius(2px);
				}
			}
			#navigation {
				.center {
					ul {
						display: none;
						li {
							width: 100%;
							a {
								display: block;
								.border-radius(0px);
								font-size: 14px;
								padding: 10px 12px;
							}
						}
					}
				}
			}
		}

		.filter-col {
			&.full-width {
				width: 100%;
			}
			&:first-child {
				&.full-width {
					width: 100%;
				}
			}
		}
		.center {
			.row {
				.content {
					.dynamic-boxes {
						>li{
							display: block;
							float: left;
							// min-height: 12em;
						}
						&.boxes-4{
		  					display: block;
		  					.clearfix();
							>li {
		  						width: 50%;
		  					}
						}
		  				&.boxes-7,
		  				&.boxes-8 {
		  					>li {
		  						width: 33.33333%;
		  					}
		  				}
		  				.inner-wrapper{
		  				}
		  				// .readMore-vBottom{
		  				// 	position: static;
		  				// 	padding-top: 1em;
		  				// 	left: 0;
		  				// 	right: 0;
		  				// }
					}
				}
			}
		}
		aside {
			.block {
				width: 100%;
				&.links {
					display: block;
					&.open {
						display: block;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	body {
		.mainContent {
			margin-top: 0;
		}
		#documents {
			padding-left: 0;
			#document-table-wrapper {
				overflow-y: auto;
				width: 100%;
			}
		}
		.xform {
			margin: 0;
		}
		.inner-wrapper {
			padding: 0 @padding-larger;
			&.editor{
				padding: @padding-larger;

                p {
                    &.qa-question {
                        font-size: 20px;
                        margin-left: 0;

                        &:before {
                            position: relative;
                            left: 0;
                            top: 0;
                            margin-bottom: 10px;
                            display: block;
                        }

                        &.qa-answer {
                            font-size: 16px;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                &.video {
                    padding-bottom: 56%;
                    margin: 15px;
                }
			}
			.categories{
				top: @padding-larger * -1;
			}
		}
		.breadcrumbs {
			display: none;
		}
		aside {
			.block {
				width: 100%;
				&.links {
					display: none;
					ul {
						background: darken(@experisPrimary, 5%);
						li {
							a {
								background: darken(@experisPrimary, 5%);
							}
						}
					}
				}
			}
			.toggle-submenu {
				display: block;
			}
		}
		#toggle-filter {
			display: block;
		}
		.filter-col {
			min-height: 0;
			&.full-width {
				// width: 100%;
				.filter-list {
					width: 100%;
					margin: 0;
					&.posts {
						width: 100%;
						margin: 0;
					}
				}
			}
			&.left-col{
				display: table-cell;
				width: 50%;
                background-color: @white;
			}
			&.right-col{
				display: table-cell;
				width: 50%;
			}
			> div{
				display: block;
			}
			.left-col {
				#tabs li.active .arrow {
					display: none;
				}
				&.filter {
					display: block;
					height: auto;
					position: relative;
					width: 100%;
                    padding-top: 0;
					&.open {
						display: block;
						width:auto;
					}
				}
			}
			.filter-list {
				display: block;
				margin: 0;
				width: 100%;
				.btn-toggle .bg-cover{
					left: 0;
					width: 100%;
					z-index: -1;
				}
				ul{
					width: 50vw;
				}
				#toggle-filter{
					overflow: hidden;
					height: 1em;
				}
			}
		}
		.start{
			.filter-col {
				display: block;
				width: 100%;
				.filter-list {
					display: block;
					width: 100%;
					&:after{
						display: none;
						// left: 0;
					}
					ul{
						width: 100%;
						position: static;
					}

				}
				> div{
					display: block;
				}
			}
		}
		.articleListing,
		.articleListing[aria-expanded]{
			.filter-col{
				display: block;
				width: 100%;
				&.right-col {
					width: 100%;
				}
				&.left-col {
					width: 100%;
				}
				.filter.left-col{
					width: 100%;
				}
				.filter-list{
					display: block;
					width: 100%;
					&:after{
						display: none;
						// faux bg
						// left: 0;
						// width: 100%;
					}
					.recent-col{
						position: static;
						padding-bottom: 2.8em; // Space for .btn-forward to recide
						ul{
							position: static;
							width: 100%;
						}
					}
					ul{
						width: 100%;
					}
					.btn-toggle{
						display: none;
					}
				}
			}
		}
		// .wide {
		// 	.filter-col {
		// 		&.left-col {
		// 			width: 7%;
		// 		}
		// 		&.right-col {
		// 			width: 93%;
		// 		}
		// 		.left-col.filter{
		// 			width: 0;
		// 		}
		// 		.filter-list{
		// 			width: 100%;
		// 			&:after{
		// 				// faux bg
		// 				left: 0;
		// 			}
		// 			ul{
		// 				position: absolute;
		// 				width: 50vw;
		// 			}
		// 		}
		// 		// #toggle-filter{
		// 		// 	height: 0;
		// 		// 	padding:0;
		// 		// }
		// 	}
		// }
		.filter-col {
			.left-col.filter{
				width: 0;
			}
			.filter-list{
				width: 100%;
				&:after{
					// faux bg
					left: 0;
				}
				ul {
					/*position: absolute;*/
					width: auto;
				}
			}
			// #toggle-filter{
			// 	height: 0;
			// 	padding:0;
			// }
		}

		.hasRightColumn {
			.filter-col {
				&.full-width {
					.left-col {
						width: 100%;
					}
					.filter-list {
						margin: 0;
						width: 100%;
					}
				}
			}
		}
		/*.filter-col {
			width: 50%;
			&:first-child {
				width: 50%;
				&.full-width {
					width: 100%;
				}
			}
			.left-col.filter {
				width: 40%;
			}
			&.full-width {
				width: 100%;
				.filter-list {
					width: 60%;
					margin-left: 40%;
					&.posts {
						width: 80%;
						margin-left: 20%;
					}
				}
			}
			.filter-list {
				width: 60%;
				margin-left: 40%;
				&.posts {
					width: 89.5%;
					margin-left: 10.5%;
				}
			}
		}*/
		.center {
			&.hasRightColumn {
				>.center {
					width: 100%;
				}
				>aside {
					position: relative;
					width: 100%;
					margin: 0;
				}
			}
			.filter-col{
				.left-col {
					display: none;
				}
			}
			.page-content {
				width: 100%;
				margin-left: 0;
				margin-top: 0;

				.details{
					padding: @padding-larger;
				}
			}
			aside {
				width: 100%;
				.links {
					margin-bottom: 0;
				}
			}
			.row {
				.content {
					width: 95%;
					padding-left: 5%;
					margin: 0;
					// &.collaborate {
					// 	article {
					// 		.column(12);
					// 	}
					// 	.img-links {
					// 		.column(12);
					// 	}

					// }
				}
			}
		}
		.profilePage {
			.page-content {
				.profile {
					width: 100%;
				}
				.latest-posts {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 656px) {
	body {

		#header {
			.top-bar {
				.toggle-intranet {
					right: 114px;
					display: block;
					background: darken(@experisPrimary, 5%);
					color: #fff;
				}
				#select-intranet {
					display: none;
					width: 100%;
					max-width: 100%;
					position: relative;
					margin: 0;
					float: none;
					clear: both;
					padding-top: 20px;
					.clearfix();
					&.active {
						display: block;
					}
					> ul {
						padding: 0;
						li {
							a {
								&.clicker {
									display: none;
								}
							}
							ul {
								display: block !important;
								position: relative;
								left: inherit;
								right: inherit;
								.box-shadow(@shadow: 0 0 0 rgba(0,0,0,0));
								.border-radius(0px);
								li {
									a {
										background: darken(@experisPrimary, 3%);
										color: white;
										&:hover {
											background: lighten(@experisPrimary, 4%);
										}
									}
								}
							}
						}
					}
				}
			}
			#search-results {
				#circuit-search-result {
					.group > ul {
						li {
							.two-columns.media {
								.bd {
									width: 100%;
								}
								> div {
									width: 100%;
									margin: 0;
									p {
										margin-left: 85px;
									}
								}
							}
						}
					}
				}
			}
		}

		.mainContent.start {

			display: block;
			&.hasRightColumn {
				aside {
					margin-top: 0;
				}
			}
		}
		// .featured-news-carousel{
		// 	padding: 0;
		// }
		.center{
			&.hasRightColumn {
				>.center {
					width: 100%;
				}
				aside {
					width: 100%;
					position: relative;
					left: inherit;
					top: inherit;
					bottom: inherit;
				}
			}
		}
		.filter-col {
			width: 100%;
			&.left-col {
				display: block;
				width: 100%;
			}
			&.right-col{
				display: block;
				width: 100%;
			}
			.filter-list{
				&:after{
					// faux bg
					display: none;
				}
				ul{
					width: 100%;
				}
				.btn-toggle{
					display: none;
				}
			}
		}
		.page-content {
            .level-links {
                > h1 {
                    padding-left: 15px;
                }

                .inner-wrapper {
                    display: block;
                    vertical-align: top;
                    box-sizing: border-box;
                    width: 100%;
                    margin-bottom: 50px;
                }
            }
			.details {
				.tags p {
					margin-bottom: 10px;
				}
				&.block {
					width: 104%;
					float: none;
					margin: 0 -2%;
					padding: 20px 15px;
					margin-bottom: 20px;
				}
			}
		}
		.center {
			.row {
		  		.content {
		  			width: 95%;
		  			padding-left: 5%;
		  			.dynamic-boxes {
		  				&.boxes-3,
		  				&.boxes-4,
		  				&.boxes-5,
		  				&.boxes-6,
		  				&.boxes-7,
		  				&.boxes-8 {
		  					>li {
		  						width: 50%;
		  					}
		  				}
		  				>li {
		  					article{
		  					}
		  					.inner-wrapper {
		  						&.three-cols {
		  							article {
		  								width: 100% !important;
		  								margin-bottom: 10px;
		  							}
		  						}
		  					}
		  				}
		  			}
		  		}
	  		}
		}
  	}
}

@media screen and (max-width: 520px) {
	body {
		header {
			.top-bar {
				.toggle-search,
				.toggle-navigation {
					background: lighten(@experisPrimary, 2%);
				}
			}
		}

		.profilePage {
			.page-content {
				.profile {
					.intro {
						.image {
							img {
								max-width: 60px;
							}
							a {
								span {
									display: none;
								}
							}
						}
						.name {
							margin-left: 80px;
							h2 {
								font-size: 16px;
							}
							.btn {
								float: none;
								display: block;
								margin: 0;
							}
						}
					}
					.contact {
						.row {
							ul {
								width: 100%;
							}
							.office {
								margin: 0;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	body {
		.arrow-link-list {
			width: 50%;
		}
		#toggle-filter {
			display: block;
		}
		.article-header{
			.time{
				margin: 0;
			}
		}
		.filter-col {
			&.full-width {
				// width: 100%;
				.filter-list {
					width: 100%;
					margin: 0;
					&.posts {
						width: 100%;
						margin: 0;
					}
				}
			}
			.left-col {
				#tabs li.active .arrow {
					display: none;
				}
				&.filter {
					height: auto;
					position: relative;
					width: 100%;
					display: none;
				}
			}
			.filter-list {
				margin: 0;
				width: 100%;
				.inner-wrapper {
					padding: 7px 0;
				}
				.recent-col {
					position: relative;
				}
				&.posts {
					.recent-col {
						ul {
							li {
								&.media {
									.img {
										margin-right: 10px;
									}
								}
							}
						}
					}
					.media {
						.img {
							.categories {
								display: none;
							}
						}
					}
				}
			}
		}
		.hasRightColumn .filter-col {
			&.full-width {
				.filter-list {
					width: 100%;
					margin: 0;
				}
				.filter-content {
					width: 100%;
					margin: 0;
				}
				.left-col {
					width: 100%;
				}
			}
		}
		.page-content {
			.details {
				&.block {
					width: 108%;
					margin: 0 -4% 20px;
				}
			}
		}
		.center{
			.row {
		  		.content {
		  			padding-left: 5%;
		  			.dynamic-boxes {
		  				>li {
		  					width: 100% !important;
		  					min-height: 0;
		  					.inner-wrapper {
		  						&.three-cols {
		  							article {
		  								width: 100% !important;
		  								margin-bottom: 10px;
		  							}
		  						}
		  					}
		  					article{
  								padding-bottom: 0;
		  					}
		  					.readMore-wrapper{
		  						bottom: 0;
		  						left: @padding-larger;

				  				.readMore-vBottom{
				  					position: relative;
				  					right: auto;
				  					left: auto;
				  					bottom: auto;
				  				}
		  					}
		  				}
		  			}
		  		}
	  		}
		}
  	}
}

@media screen and (max-width: 430px) {
  	body {
  		#header {
			.top-bar {
				#logo {
					img {
						padding-top: 0px;
						max-height: 43px;
					}
				}
			}
		}
	}
	.align-distributed,
	.align-justified{
		.img-col-1,
		.img-col-2,
		.img-col-3,
		.img-col-4,
		.img-col-5,
		.img-col-6,
		.img-col-7,
		.img-col-8{
			max-width: 100%;
			width: 100%;
			img{
				width: 100%;
			}
		}
	}
	.editor .has-caption{
		margin-bottom: 1em;
	}
}

@media screen and (max-width: 360px) {
  	body {
		.featured-news-carousel .flex-control-nav{
			right: 62px;
			bottom: 15px;
			text-align: right;
			font-size: 0;
		}
	}
}

@media screen and (max-width: 330px) {
  	body {
  		#header {
			.top-bar {
				#logo {
					margin-left: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
//@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* iPhone, Android rules here */

  	body {
  		header {
			.top-bar {
				#logo {
					width: 100px;
				}
			}
		}
		.editor {
			.col {
				&.half,
				&.one-three,
				&.two-three {
					width: 100% !important;
					margin: 0 0 10px;
				}
			}
		}
  		.arrow-link-list {
			width: 100%;
		}
		.center.group-header {
			.img {
				float: none;
			}
		}
		.page-content {
			.level-links .media {
				.img {
					float: none;
				}
			}
			.details {
				&.block {
					width: 110%;
					margin: -15px -5% 20px;
				}
			}
		}
  	}

}
