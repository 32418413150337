/// <reference path="variables.less" />
/// <reference path="mixins.less" />
/// <reference path="grid.less" />
/// <reference path="bootstrap/variables.less" />

.market,
.bookings {
	.clearfix();
	padding: 20px;
	background-color: #fff;

	h2 {
		font-weight: bold;
		padding: 0;
		margin: 0 0 20px 0;

		&:before {
            .icon-before("\f073");
			padding-right: 7px;
		}
	}

    &.market h2:before {
		.icon-before("\f07a");
    }

    .btn {
        border: none;
    }

	.info-message {
		.clearfix();
		padding: 20px;
		margin-bottom: 20px;
		background-color: lighten(@mainTurkoise, 50%);
		.border-radius(2px);
		line-height: 20px;
		font-size: 14px;

        h3 {
            background: none;
            padding: 0;
            margin-bottom: unit((@gutter-width / 2), px);
        }

		p:last-of-type {
			margin-bottom: 0;
		}

		ul {
			list-style-type: disc;
			padding: 10px 0 10px 20px;
			li {
				margin-bottom: 10px;
			}
		}

        & + p {
             margin-bottom: unit(@gutter-width, px);
        }
	}

	.dates {
		.field-validation-error {
			margin-top: 10px;
		}
	}

	.blankstate {
		margin: 0 0 20px 0;
		padding: 10px;
		background: #f0f0f0;
		p {
			font-size: 14px;
			margin-bottom: 0;
		}
	}

	h3 {
		padding: 15px 10px;
		background-color: lighten(@lightGray, 2%);
		margin-bottom: 1px;

		small {
			font-size: 14px;
			font-weight: 400;
			color: darken(@lightGray, 40%);
		}
	}

	.booking-form {
		.form-control[readonly],
		input[type="text"].form-control[readonly] {
			cursor: default;
			background-color: #fff;
		}
	}

	.form-actions {
		.btn {
			margin: 0 0 0 3px;
		}
	}

	.object {
		.description {
			background-color: lighten(@lightGray, 5%);
			padding: 10px;
			margin-bottom: 20px;
			a {
				text-decoration: none;
				color: @mainTurkoise;
				&:hover {
					color: darken(@mainTurkoise, 10%);
				}
			}
		}
		.pagination {
			padding: 20px 0 0 0;
		}
	}

	.table-scrollable {
		width: 100%;
		.clearfix();
		overflow-y: hidden;
		overflow-x: auto;
		margin-bottom: 20px;
		table {
			margin-bottom: 0;
		}
	}

	table {

		border-collapse: collapse;
		width: 100%;

		td {
			border: 1px solid @lightGray;
		}
		thead {
			td,
			th {
				background: #f0f0f0;
				padding: 15px 0 15px 10px;
				vertical-align: top;
				text-align: left;
				font-weight: 700;
				border: none;
				border-bottom: 1px solid darken(@lightGray, 5%);

				&.min {
					width: 160px;
				}
				&.small {
					width: 1%;
					padding-left: 0;
					text-align: center;
				}
				&.min.last {
					width: 1%;
				}
				&.last{
					padding-right: 1%;
				}
				[class^="icon-"],
				[class*=" icon-"] {
					font-size: @font-size-base - 1;
					padding-right: 5px;
				}
				.start {
					// &:before {
					// 	.icon-before("\f08b");
					// 	font-size: @font-size-base - 1;
					// 	padding-right: 5px;
					// }
				}
				.end {
					// &:before {
					// 	.icon-before("\f090");
					// 	font-size: @font-size-base - 1;
					// 	padding-right: 5px;
					// }
				}
				.user {
					// &:before {
					//    .icon-before("\f007");
					//    font-size: @font-size-base - 1;
					// 	padding-right: 5px;
					// }
				}
				.nr {
					display: block;
					// &:before {
					// 	.icon-before("\f022");
					// 	font-size: @font-size-base - 1;
					// }
				}
				.comment{
					// &:before {
					// 	.icon-before("\f075");
					// 	font-size: @font-size-base - 1;
					// 	padding-right: 5px;
					// }
				}
			}
		}
		tbody {
			tr {
                @border: 1px solid darken(@lightGray, 5%);

                td:first-child {
                    @wh: 60px;

                    padding: 0;
                    width: @wh;
                    height: @wh;
                    text-align: center;
                    
					border-right: @border;

                    a,
                    img {
                        display: inline-block;
                    }
                }

                td {
                    vertical-align: middle;
                }

				&.validation-error,
				&.validation-error:nth-child(odd) {
					td {
						background-color: lighten(#f04131, 30%);
					}
				}
				/*
				&.booked,
				&.booked:nth-child(odd) {
					td {
						background-color: lighten(@sapaLightGreen, 30%);
					}
				}
				*/
				&.editing,
				&.editing:nth-child(odd) {
					td {
						//font-weight: bold;
						background-color: lighten(@mainTurkoise, 45%);
					}
				}
				&:nth-child(odd) {
					td {
						//background: #f0f0f0;
					}
				}
				td {
					background: #f0f0f0;
					padding: 10px;
					//vertical-align: top;
					border: none;
					border-bottom: @border;
					.start,
					.end {
						// &:before {
						//    .icon-before("\f017");
						//    font-size: @font-size-base - 1;
						//    padding-right: 5px;
						// }
					}
					a {
						text-decoration: none;
					}
					.icon-comment {
						position: relative;
						padding-left: 3px;
						cursor: default;
					}
				}
				th {
					padding: 10px;
					background: @darkGray;
					color: #fff;
					text-align: left;
					vertical-align: top;
				}
			}
		}
	}
	.toggle {
		&.closed {
			display: none;
		}
		&.opened {
			display: block;
		}
	}
}

.marketItem {
    @gutter: ~"@{gutter-width}px";

    h3 {
        margin-bottom: @gutter;

        & ~ p {
            padding: 0 10px;
        }
    }

    .images {
        .clearfix();
        background: #fff;
        margin: 0;

        li {
            .box-sizing(border-box);
            max-width: 100%;
            margin: 0;

            img {
                display: block;
                max-width: 100%;
                width: auto;
            }
        }
    }

    .item-wrapper {
        @halfGutter: unit(@gutter-width / 2, px);
        @border: @halfGutter solid #fff;

        padding-right: unit(@gutter-width + @halfGutter, px);

        small {
            display: block;
            margin-bottom: @halfGutter;
        }
        .row {
            margin-bottom: unit(@gutter-width, px);
        }

        .col {
            float: left;
            width: 50%;
            box-sizing: border-box;
            border: @border;
            border-width: 0 @halfGutter;
        }

        @media screen and (max-width: 768px) {
            .col {
                float: none;
                width: auto;
                border: none;
                padding: 0 @halfGutter @halfGutter;
            }
        }

        .employee {
            border-top: 5px solid #e9e9e9;
            padding: @halfGutter;

            .img {
                margin: 0;
            }

            @imgMaxWidth: 150px;

            img {
                width: auto;
                max-width: @imgMaxWidth;
                float: left;
                margin: 0 @halfGutter @halfGutter 0;
            }

            .img ~ p {
                margin-left: (@imgMaxWidth + @halfGutter);
            }

            @media screen and (max-width: 480px) {
                img {
                    float: none;
                    margin: @halfGutter 0;
                }

                .img ~ p {
                    margin-left: 0;
                }
            }
        }

        .backLink {
            clear: both;
            .clearfix();
            
            padding: 0 @halfGutter;
            margin: 0;
        }


        & ~ form,
        & ~ h3 {
            margin: unit(@gutter-width, px) unit(@gutter-width, px) unit(@gutter-width, px) 0;
        }
    }

    @media screen and (max-width: 480px) {
        .xform .row .half {
            &:first-child {
                margin-bottom: unit(@gutter-width, px);
            }

            &,
            &.last {
                float: none;
                width: 100%;
                margin-left: 0;
            }
        }
    }
}