table{

	&.fixed{
		table-layout: fixed;
	}
	thead{
		td, th{

			&.emptyColumn{
				width: 1.8em;
				padding-right: 0;
				text-overflow: ellipsis;
				&:hover{
					cursor: pointer;
				}
			}

		}
	}
	td.tooltipped{
		.tooltipped_content{
			text-overflow:ellipsis;
			overflow: hidden;
		}
	}

	// User set text sizes.
	.tableFontSize(@size){
		&.table-text-size-@{size}{
			th, td, th *, td *{
				font-size: unit(@size, px);
			}
		}
	}
	.tableFontSize(12);
	.tableFontSize(14);
	.tableFontSize(16);
	.tableFontSize(18);
	
	.tableCellFontSize(@size){
		th.text-size-@{size},
		td.text-size-@{size},
		th.text-size-@{size} *,
		td.text-size-@{size} *{
			font-size: unit(@size, px);
		}
	}
	.tableCellFontSize(12);
	.tableCellFontSize(14);
	.tableCellFontSize(16);
	.tableCellFontSize(18);

}
.canhyphenate table{

	.hyphens(auto);
	word-wrap: break-word;
	table-layout: auto;

	thead tr td{
		.hyphens(none);
	}
	thead tr td,
	tbody tr td{
		text-overflow: unset;
	}

}