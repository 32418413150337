

.profilePage {

	.left-col {
		&.narrow {
			//width: 26px;
			width: 2%;
		}
	}
	.page-content {
		.clearfix();
		width: 98%;
		margin-left: 2%;
	}

	.xform {
		&.photo {
			max-width: 100%;
			fieldset {
				background: #eee;
			}
		}
		fieldset {
			padding: 20px;
			background-color: #fff;
			textarea {
				min-height: 200px;
			}
		}
		h2 {
			font-weight: bold;
			padding: 0;
			margin: 0 0 20px 0;
			&:before {
			  	.font-icon(@fa-var-edit);
			  	padding-right: 7px;
		  	}
		}
		.btn-success {
			&:before {
			  	.font-icon(@fa-var-check-circle);
			  	padding-right: 7px;
		  	}
		}
		.btn-delete {
			&:before {
			  	.font-icon(@fa-var-times);
			  	padding-right: 7px;
		  	}
		}
	}

	.xform,
	.subscriptions {
		.info-message {
			.clearfix();
			padding: 20px;
			margin-bottom: 20px;
			background-color: lighten(@mainTurkoise, 50%);
			.border-radius(2px);
			p {
				font-size: 15px;
				line-height: 20px;
			}
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.subscriptions {
		.clearfix();
		padding: 20px;
		background-color: #fff;
		h2 {
			font-weight: bold;
			padding: 0;
			margin: 0 0 20px 0;
			&:before {
			  	.icon-before("\f022");
			  	padding-right: 7px;
		  	}
		}
        h3 {
            padding: 15px 10px;
            background-color: lighten(@lightGray, 2%);
            margin-bottom: 1px;
        }
		ul {
			.clearfix();
			list-style-type: none;
            margin-bottom: 10px;
			li {
				position: relative;
				padding: 10px;
				background-color: lighten(@lightGray, 5%);
				margin-bottom: 1px;

                &.forced {
                    //background-color: lighten(@lightGray, 2%);
                    .item {
                        color: #999;
                        &:before {
                            .icon-before("\f0a9");
                            color: #999;
                        }
                        &:hover {
                            color: darken(@mainTurkoise, 10%);
                            &:before {
                              color: darken(@mainTurkoise, 10%);
                            }
                        }
                    }
                }

				.item {
					text-decoration: none;
					color: @mainTurkoise;
					&:before {
                       .icon-before("\f058");
					  	padding-right: 10px;
                        color: @mainTurkoise;
				  	}
                    &:hover {
                        color: darken(@mainTurkoise, 10%);
                        &:before {
                          color: darken(@mainTurkoise, 10%);
                        }
                    }
				}

				.remove {
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 30px;
					text-decoration: none;
					text-align: center;
					height: 100%;
					line-height: 37px;
					background-color: lighten(@lightGray, 2%);
					color: #222;
					&:hover {
						background-color: darken(@lightGray, 2%);
					}
				}
			}
		}
	}

	.profile {
		width: 65%;
		float: left;
		.intro {
			.clearfix();
			padding: 20px 20px 0px 20px;
			.image {
				float: left;
				max-width: 160px;
				img {
					max-width: 160px;
				}
				a {
					text-decoration: none;
					position: relative;
					display: block;
					span {
						cursor: pointer;
						background: transparent;
						background-color: rgba(0,0,0,0.7);
							-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#B2000000,endColorstr=#B2000000)"; /* IE8 */
					    	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B2000000",endColorstr="#B2000000");   /* IE6 & 7 */
					    	zoom: 1;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						display: block;
						color: #fff;
						padding: 10px;
						font-size: 12px;
						&:before {
						  	.icon-before("\f03e");
						  	padding-right: 7px;
					  	}
					}
					&:hover {
						span {
							background-color: rgba(0,0,0,0.9);
						}
					}
				}
			}
			.name {
				overflow: hidden;
				margin-left: 180px;
				h2 {
					padding-top: 10px;
					font-weight: bold;
				}
				ul {
					li {
						margin-bottom: 7px;
						color: #444;
						font-size: 14px;
						i {
							margin-right: 5px;
						}
					}
				}
				.btn {
					margin: 0 0 5px 5px;
					float: right;
					color: #222;
					font-weight: normal;
					background-color: @lightGray;
					padding: 12px 16px;
					font-size: 14px;
					&:before {
					  	.icon-before("\f044");
					  	padding-right: 7px;
				  	}
				  	&:hover {
					  	background-color: darken(@lightGray, 5%);
				  	}
				}
			}
		}
		.contact {
			.clearfix();
			padding: 20px;
			ul {
				float: left;
				width: 55%;
				li {
					margin-bottom: 3px;
					a {
						display: block;
						background-color: @lightGray;
						color: #333;
						padding: 10px;
						font-size: 14px;
						border-radius: 3px;
						text-decoration: none;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						i {
							margin-right: 5px;
						}
						&:hover {
							background-color: darken(@lightGray, 5%);
						}
						&.email {
							color: #fff;
							background-color: @mainTurkoise;
							&:hover {
								background-color: darken(@mainTurkoise, 5%);
							}
						}
					}
				}
			}
			.office {
				float: left;
				width: 43%;
				margin-left: 2%;
				background-color: @gray-lightest;
				font-size: 14px;
				border-radius: 3px;
				.padding {
					padding: 15px;
				}
				p {
					font-size: 14px;
					line-height: 18px;
					color: #444;
					margin-bottom: 0;
				}
			}
		}
		.extra,
		.about {
			.clearfix();
			padding: 20px;
			h2 {
				font-weight: bold;
				font-size: 18px;
			}
			p {
				font-size: 14px;
				line-height: 19px;
				color: #444;
			}
		}
		.extra {
			padding-bottom: 0;
		}
	}
	.latest-posts {
		width: 35%;
		float: right;
		background-color: @gray-lightest;
		.feed-header {
			padding: 20px;
			h2 {
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 15px;
			}
			.create-event {
				.clearfix();
				width: 100%;
				.wysiwyg {
					.box-sizing(border-box);
					width: 100%;
					padding: 5px;
					height: 80px;
					background-color: #fff;
					border: 2px solid @lightGray;
					font-family: 'SegoeUI', 'Open Sans', Arial, sans-serif;
					font-size: 14px;
					&:focus {
						.box-shadow(none);
						border-color: darken(@lightGray, 5%);
						outline: none;
						color: #444;
					}
				}
				.file {
					.box-sizing(border-box);
					display: block;
					overflow: hidden;
					padding: 5px;
					width: 100%;
					margin: 5px 0 10px 0;
					font-size: 12px;
					background-color: #fff;
					border: 1px solid darken(@lightGray, 5%);
				}
				.btn {
					margin: 5px 0 0 0;
					float: right;
					background-color: @mainTurkoise;
					padding: 12px 16px;
					font-size: 14px;

					&:before {
					  	.icon-before("\f058");
					  	padding-right: 7px;
				  	}
					&:hover {
					  	background-color: darken(@mainTurkoise, 5%);
				  	}
				  	&.disabled {
				  		cursor: default;
				  		background-color: @lightGray;
					  	color: @darkGray;
				  	}
				}
			}
		}
		.blankstate {
			margin: 10px 20px;
			padding: 10px;
			p {
				margin: 0;
			}
		}
		.feed {
			padding: 10px 20px;
			li {
				position: relative;
				padding: 5px 10px;
				margin-bottom: 10px;
				border-left: 3px solid #e5e5e5;

				&.selected {
					background-color: lighten(@sapaRed,30%);
					border-left-color: @sapaRed;
				}

				.meta {
					.inlineblock();
					color: @darkGray;
					font-size: 13px;
					margin-bottom: 5px;
				}
				h3 {
					font-size: 14px;
					margin-bottom: 2px;
					font-weight: bold;
				}
				p {
					margin-bottom: 2px;
					font-size: 14px;
					line-height: 19px;
					color: #555;
					a {
						color: @mainTurkoise;
						&:hover {
							color: darken(@mainTurkoise, 10%);
						}
					}
				}

				.attachment {
					margin-top: 10px;
					display: block;
					overflow: hidden;
					font-size: 14px;
					text-decoration: none;
					padding: 2px 0;
					color: @mainTurkoise;
					&:hover {
						color: darken(@mainTurkoise, 10%);
					}
					&:before {
						.icon-before("\f0f6");
						padding-right: 5px;

					}
				}
			}
			.btn-delete{
				.btn-delete-admin();
			}
		}
		.pagination {
			padding: 10px 20px 20px 20px;
			li {
				.prev,
				.next,
				.number {
					border: none;
					background-color: #f5f5f5;
					&:hover {
						background-color: #e2e2e2;
					}
				}
				&.disabled {
					.prev,
					.next {
						&:hover {
							background-color: #f5f5f5;
						}
					}
				}
				&.active {
					.number {
						background-color: #e3e3e3;
						&:hover {
							background-color: #e3e3e3;
						}
					}
				}
			}
		}

	}
}

/*
 * imgAreaSelect
 */

.imgcrop {
	position: relative;
	float: left;
	display: block;
	margin: 0 20px 20px 0;
	width: 100%;
	height: auto;
}
.preview {
	display: block;
	float: left;
	.preview-header {
		float: left;
		clear: both;
		font-weight: bold;
		font-size: 13px;
	}
}
#crop-preview {
	position: relative;
	float: left;
	overflow: hidden;
	width: 100px;
	height: 100px;
	clear: both;
	img {
		position: relative;
		float: left;
		display: block;
		max-width: inherit;
		height: auto;
	}
}

.imgareaselect-border1 {
	background: url('/Content/js/imgareaselect/css/border-v.gif') repeat-y left top;
}

.imgareaselect-border2 {
    background: url('/Content/js/imgareaselect/css/border-h.gif') repeat-x left top;
}

.imgareaselect-border3 {
    background: url('/Content/js/imgareaselect/css/border-v.gif') repeat-y right top;
}

.imgareaselect-border4 {
    background: url('/Content/js/imgareaselect/css/border-h.gif') repeat-x left bottom;
}

.imgareaselect-border1, .imgareaselect-border2,
.imgareaselect-border3, .imgareaselect-border4 {
    filter: alpha(opacity=50);
	opacity: 0.5;
}

.imgareaselect-handle {
    background-color: #fff;
    border: solid 1px #000;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.imgareaselect-outer {
    background-color: #000;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.imgareaselect-selection {
}