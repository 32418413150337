@transitionLength : 300ms;

.hasRightColumn {
    .filter-col {
        // min-height: 500px;
        &.full-width {
            width: 100%;
            .left-col {
                width: 20%;
            }
            .filter-list {
                width: 80%;
                margin-left: 20%;
            }
            .filter-content {
                width: 80%;
                margin-left: 20%;
                height: 100%;
            }
        }
    }
}
.columncontainer-row{
    display:table-row;
}
// TODO: Move all .articleListing related column styles to this section. -LR
.articleListing,
.start {

    > ul li{
        &:nth-child(odd),
        &.odd{
            background-color: lighten(@gray-lightest, 1%);
        }
    }
    .filter-col {
        .filter-list {
            display: table-cell;
            float: none;
            &:after{
                // faux background which covers the list when an article is in wide
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                left: 30%;
                bottom: 0;
                height: 999%; // IE doesn't take the height of the next relatively positioned element but rather the direct parent. -LR
                background: #fff;
                z-index: -1;
            }
            .recent-col{
                // Isn't absolutely positined any longer.
                // padding-bottom: 4em; // space for the absolutely positioned next and previous links to recide.
            }
        }
        > div{
            display: table;
        }
    }
    .page-content{
        padding-top: @padding-larger;
    }
    .left-col{
        position: relative;
    }
}
.start{
    .filter-list .recent-col{
        padding-bottom: 3em; // space for the bottom to recide.
        & > a{
            //position: absolute;
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 900;
            .colorMultifier(color, Primary);

            i {
                color: @lightGray;
            }
        }
    }
}
.articleListing{
    &[aria-expanded="true"]{

        table-layout: fixed;

        .filter-col {
            .filter-list{

                width: 25%;

                .recent-col{
                    position: absolute;
                    ul{
                        // pull the list out of the flow
                        width: 400px;
                    }
                }

                .btn-toggle{
                    .bg-cover{
                        opacity: 1;
                        z-index: 1;
                        width: 25%;
                        left: 0%;
                        zoom: 1;
                    }
                }
                &:after{
                    // faux background
                    left: 75%;
                }
            }
            &.left-col{
                width: 20%;
            }
            &.right-col {
                width: 80%;
            }
            .filter.left-col{
                width: 75%;
            }
        }
    }
}

.filter-col {

    .clearfix();
    // float: left;
    // position: relative;
    width: 50%;
    // min-height: 500px;
    overflow: hidden;

    &.full-width {
        width: 100%;
        .left-col {
            width: 15%;
        }
        .filter-list {
            width: 85%;
            margin-left: 15%;
        }
    }
    >div {
        .clearfix();
        display: table; // Documents page
        table-layout: fixed;
        width: 100%;
        height: 100%;
        zoom: 1;
    }
    .filter-list {
        // position: relative;
        position: static;
        width: 70%;
        // margin-left: 30%;
        height: 100%;

        // !!! Uncomment when animation work is complete !!!
        // .transition(width @transitionLength);


        .btn-toggle{

            position: static;
            width: 100%;
            margin: 0;
            .box-sizing(border-box);
            font-size: 24px;
            z-index: 1;

            .icon-reorder{
                .font-icon(@fa-var-reorder);
                position: relative;
                z-index: 2;
            }
            &:hover{
                color: @btn-active-bg;
                cursor: pointer;
            }

            .bg-cover{
                position: absolute;
                bottom: 0;
                left: 30%;
                width: 70%;
                height: 999%; // For IE compatibility
                background-color: @gray-lightest;
                opacity: 0;
                top:0;
                z-index: -1;
                // !!! Uncomment when animation work is complete !!!
                // .transition(opacity @transitionLength);
            }
        }
    }
    &.right-col {
        // float: right;
        display: table-cell;
        width: 50%;
        // margin-left: 50%;
        background-color: white;
        // !!! Uncomment when animation work is complete !!!
        // .transition(width @transitionLength);
    }
}
.right-col {
    display: table-cell;
    &.filter-list{
        float: none;
    }
}
.left-col {
    // position: absolute;
    // left: 0;
    display: table-cell;
    height: 100%;
    width: 50%;
    vertical-align: top;
    z-index: 0;
    // !!! Uncomment when animation work is complete !!!
    // .transition(width @transitionLength);

    &.posts {
        // margin-top: 50px;
        #tabs {
            li.active {
                .arrow {
                    border-right-color: #fff;
                }
            }
        }
    }
    #tabs {
        padding: 0;
        clear: both;
        li {
            display: block;
            width: 100%;
            position: relative;
            .arrow {
                display: none;
            }
            a {
                position: relative;
                padding: 12px 10px;
                display: block;
                text-decoration: none;
//					background: transparent;
                color: @white;
                font-size: 12px;
                line-height: 15px;
                font-weight: normal;
//                text-transform: none;
                border-bottom: 1px solid fade(@white, 40%);
                &:hover {
                    .colorMultifier(background, PrimaryLight75);
                    color: white;
                }
            }
            &.active {
                > a {
                    color: #fff;
                    .colorMultifier(background, PrimaryLight75);

                    /*
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 0;
                        height: 0;
                        top: 50%;
                        margin-top: -10px;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-right:10px solid @white;
                    }
                    */
                    
                }
                .arrow {
                    position: absolute;
                    top: 0;
                    right: 0;
                    top: 7px;
                    //margin-top: -9px;

                    display: -moz-inline-box;
                    display: none;
                    /* Use font-size to control the size of the arrow. */
                    height: 0;
                    width: 0;
                    font-size: 16px;
                    line-height: 0;
                    vertical-align: middle;

                    border-style: dashed;
                    border-color: transparent;
                    border-width: 9px;
                    border-right-width: 10px;
                    border-right-style: solid;
                    border-right-color: @gray-lightest;
                }
            }
            &.recent-updates {
                margin-top: 20px;
                a {
                    .colorMultifier(background, Primary);
                    color: white;
                    &:hover {
                        .colorMultifier(background, Primary50);
                        color: white;
                    }
                }
                &.active {
                    a {
                        background: darken(@sapaWarmGrey, 10%);
                        color: #fff;
                        &:hover {
                            background: darken(@sapaWarmGrey, 15%);
                            color: white;
                        }
                    }
                    /*.arrow {
                        top: 27px;
                    }*/
                }
            }
            ul {
                li {
                    a {
                        padding-left: 20px;
                    }
                    ul {
                        li {
                            a {
                                padding-left: 32px;
                                //background: #b4b4b4;
                                //border-color: #a0a0a0;
                                &:before {
                                    margin-left: -12px;
                                    content:"\203A";
                                    padding-right: 8px;
                                }
                            }
                            ul {
                                li {
                                    a {
                                        padding-left: 43px;
                                        &:before {
                                            margin-left: -10px;
                                            content:"-";
                                            padding-right: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
#toggle-filter {
    display: none;
    position: relative;
    margin: 0;
    padding: 15px 15px;
    min-width: 100px;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
//    text-transform: none;
    background: @gray-light;
    // !!! Uncomment when animation work is complete !!!
    // .transition(all @transitionLength);

    i {
        float: right;
        margin-right: 10px;
    }
}
.filter-list {

    .clearfix();
    float: left;
    width: 100%;
    position: relative;

    &.featured-news-carousel {
        width: 25%;
    }
    &.posts {
        background: #fff;
        .recent-col {
            ul {
                li {
                    border-bottom: none;
                    margin-bottom: 25px;

                    &.media {
                        .img {
                            width: 20%;
                            margin-right: 25px;
                            position: relative;
                            .categories {
                                white-space: normal;
                                p,
                                a {
                                    color: #fff;
                                    text-transform: none;
                                    font-size: 12px;
                                    background: @sapaOrange;
                                    margin: 0;
                                }
                            }
                        }
                        .bd {
                            a {
                                text-decoration: none;
                            }
                        }
                    }

                    a {
                        .colorMultifier(color, Primary);
                    }
                    p {
                        margin-bottom: 20px;
                    }
                    .time {
                        color: #848484;
                    }
                    h2 {
                        font-size: 20px;
                        margin: 5px 0 10px;
                    }
                    .arrow-link {
                        color: @experisPrimary;
                        font-size: 14px;
                        &:before{
                            width: 1em;
                        }
                    }
                }
            }
        }
        .icon-time{
            display: none;
        }
    }
    .inner-wrapper{
        padding-top: 1em;
    }
    .recent-col {
        //background-color:#81a4d2;
        //margin-bottom: -3.4em;
        .blankstate {
            margin-top:3.3em;
        }
        // position: relative; // interfering with IE8
        width: 100%;
        height: 100%;
        .box-sizing(border-box);

        h1 {
            padding-left: 10px;
        }
        ul {
            //margin-top:3.3em;
            margin-left:1px;
            //border-left: 3px solid #bfbfbf;
            //border-left:3px solid #6390c6;
            li {
                position: relative;
                padding: 1em;
                background-color: @white;
                border-bottom:1px solid #bfbfbf;

                //border-bottom: 1px solid #bfbfbf;
                //border-right: 1px solid #bfbfbf;

                .clearfix();
                &:first-child {
                    //border-top: 1px solid #bfbfbf;
                }

                
                &:nth-child(odd){
                    //.colorMultifier(background, Gray0);
                }
                

                &.notPublished {
                    p,
                    h3 {
                        color: #ccc;
                    }
                }

                .img {
                    float: left;
                    width: 40px;
                    margin-left: -14px;
                    margin-top: 5px;
                    img {
                    }
                }
                .description {
                    overflow: hidden;
                    margin-left: 40px;
                }
                .meta {
                    // float: right;
                    max-height: 40%;
                    margin-bottom: 3px;
                    // margin-left: 1em;
                    font-size: @font-size-smaller;
                    line-height: 1.6em;
                    // text-align: right;
                    color: #a6a6a6;
                    a {
                        color: #848484;
                    }
                    .tag{
                        margin-left: 1em;
                    }
                }
                .user {
                    // border-bottom: 1px solid #c9c9c9;
                    padding-bottom: 12px;
                    h3 a {
                        margin-top: 3px;
                        font-weight: bold;
                        color: @experisPrimary;
                        &:hover {
                            color: darken(@experisPrimary, 10%);
                        }
                    }
                }
                .searchresult{
                    [class^="icon-"],
                    [class*=" icon-"]{
                        padding-right: 1.3em;
                    }

                    span.notPublished:before {
                        display: inline-block;
                        font-size: 1em;
                        font-family: 'FontAwesome';
                        content: "\f071";
                        color: @sapaOrange;
                    }
                }

                &.active {
                    //background: #e3e3e3;
                    a {
                        font-weight: bold;
                    }
                }
                a {
                    text-decoration: none;
                    .colorMultifier(color, Primary);

                    &:hover {
                        text-decoration: underline;
                        .colorMultifier(color, Primary75);
                    }
                }
                > a,
                >.searchresult {
                    // border-bottom: 1px solid #c9c9c9;
                    text-decoration: none;
                    display: block;
                    // padding-bottom: 9px;
                    //.clearfix();
                    &.img {
                        border: none;
                    }
                }
                h3 {
                    font-size: @font-size-h5;
                    margin-bottom: 0.3em;
                    height:57px;
                    overflow: hidden;
                }
                p {
                    @max-lines: 2;
                    font-size: @font-size-small + 1;
                    line-height: unit( @line-height-small, em );
                    max-height: unit( (@font-size-small + 1) * @line-height-small * @max-lines, px );
                    overflow: hidden;
                    margin: 0 0 0.3em;
                    color: #666;
                    &.author {
                        font-style: italic;
                    }
                }
                &:last-child {
                    .content {
                        border-bottom: none;
                    }
                }
                &.selected {
                    background-color: lighten(@sapaRed,35%);
                }
            }
        }
        ul.search-result{
            li{
                .meta {
                    float: right;
                    margin-right: 0.4em;
                    text-align: right;
                    max-height: 40%;
                    // margin-bottom: 0;
                    // font-size: @font-size-smaller;
                    // line-height: 1.6em;
                    // color: #a6a6a6;
                    // a {
                    // 	color: #848484;
                    // }
                    // .tag{
                    // 	margin-left: 1em;
                    // }
                }
            }
        }
        ul.filter-bar{
                .clearfix();
            li{
                display: inline-block;
                padding-bottom: 1em;
                font-size: @font-size-base - 2;
                font-weight: bold;
                background-color: transparent;

                a{
                    // &:visited,
                    // &:visited p{
                    // 	color: @black;
                    // }
                }
            }
            .toggle{
                .icon{
                    font-size: @font-size-base - 4;
                    margin-right: 0.6em;
                }
            }
            .filter-type{
                padding-left: 10px;
            }
            .filter-relevance{
                padding-right: 0;
                padding-left: 0;
            }
            .filter-date{
                float: right;
            }
        }
        > a {
            .inlineblock();
            bottom: 0;
            padding: 0.5em 14px;
            // margin-top: 10px;
            margin-bottom: 0.5em;
            margin-left:0.5em;            
            font-size: 13px;
            .hoverUnderline;
            i {
                color:#466ea5 !important;
                margin-right:5px;
            }
        }
        .btn-back{

        }
        .btn-forward{
            float: right;
        }
        .btn-delete{
            .btn-delete-admin();
        }
    }
}
.pagination,
body .filter-list .recent-col .pagination {
  display: inline-block;
  padding: 10px 14px 10px 14px;
  margin: 0;
  font-size: 13px;

  > li {
	display: inline;
	float: left;
	padding: 0;
	margin-right: 2px;
	background-color: transparent;
	> a,
	> span {
	  float: left;
	  padding: 8px 10px;
	  line-height: 12px;
	  text-decoration: none;
	  background-color: transparent;
	  border: 1px solid transparent;
	  .border-radius(2px);
	}
	&:first-child {
	  > a,
	  > span {
		border-left-width: 1px;
	  }
	}
	&:last-child {
	  > a,
	  > span {
	  }
	}
  }

  > li > a:hover,
  > li > a:focus,
  > .active > a,
  > .active > span {
	background-color: #ddd;
	border: 1px solid #ddd;
  }
  > .active > a,
  > .active > span {
	color: #222;
	cursor: default;
  }

  > .disabled {
	> span,
	> a,
	> a:hover,
	> a:focus {
	  color: #999;
	  background-color: @gray-lightest;
	  cursor: not-allowed;
	}
  }
}
body #search-results {
    li.notPublished {
        color: #ccc;

        span.notPublished:after {
            display: inline-block;
            font-size: 1em;
            font-family: 'FontAwesome';
            content: "\f071";
            color: @sapaOrange;
            margin-right: 0.5em;
        }
    }
}