// Defaults which you can freely override
@column-width: 65;
//@gutter-width: 28;
@columns: 12;

html,body {
	height: 100%;
}
body {
	font-family: @fontLight;
	font-weight: 400;
	// font-size: 100%;
	font-size: @font-size-base;
	line-height: @line-height-base;
    background-color: #fafafa;
}
img { 
    -ms-interpolation-mode: bicubic; 
}
.center {
	width: 100%;
	max-width: 1144px;
	margin: 0 auto;
	position: relative;
	clear: both;
	.clearfix();
	background: #fff;
	overflow: hidden;
	&.noBackground {
		background: none;
	}
	&.hasRightColumn {
		position: relative;
		>.center {
			width: 75%;
			float: left;
			clear: none;
		}
		>aside {
			width: 23%;
			margin-top: 20px;
			background: white;
			/*position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			overflow: auto;*/
		}
		.mainContent {
			min-height: 500px;
			table-layout: auto;
			display: block;
			.editor {
//				min-height: 310px;
//				&.section,
//                &.video {
//					min-height: 0px;
//				}
			}
		}
		.inner-wrapper{
//			padding-right: 20px;
		}
	}
	&.header-block {
		margin: 10px auto 0px auto;
		background: @lightGray;
		h2 {
			margin: 0;
			padding: 10px 10px;
			text-transform: none;
			font-size: 13px;
			font-weight: bold;
		}
	}
}
.inner-wrapper {
	padding: 0 @padding-xlarge;
	.clearfix();
}
.block {
	position: relative;
	width: 100%;
	background: @lightGray;
	.box-sizing(border-box);
}

.mainContent {
	&.start,
	&.articleListing{
		display: table; // Enables equal height resizing according to content. See more in filter.less
		table-layout: fixed;
		.time{
			.inlineblock();
			padding-bottom: 0;
		}
	}
	&.start {
		margin: 0 auto;
		background: #fff;

        /*visibility: hidden;

        & ~ div {
            visibility: hidden;
        }

        & ~ div.visible {
            visibility: visible;
        }*/
	}

	position: relative;
	margin: 20px auto 0 auto;
	// padding-bottom: 20px;

	&.noBackground {
		background: none;
	}
	// &.equalHeight {
	// 	margin: 20px auto 0;
	// }
}

.time {
	font-size: 12px;
	position: relative;
	&.right {
		position: absolute;
		right: 0px;
		top: 0px;
	}
}
.link-icon {
	font-size: 0.8em;
	margin-right: 0.3em;
}
i.icon-chevron-right,
i.icon-chevron-left {
	.link-icon();
}

p [class^="icon-"],
p [class*=" icon-"],
h3 [class^="icon-"],
h3 [class*=" icon-"],
h4 [class^="icon-"],
h4 [class*=" icon-"] {
	margin-right: 0.3em;
}
i.icon-time,
p i.icon-time,
.time i.icon-time {
	margin-right: 0.4em; // Round icons require more space.
}

.readMore {

  	.readMoreLink();
  	font-weight: bold;

}

.readMore-wrapper{
	// Dynamically created wrapper for use with dynamic-boxes
	// height: 0;
	// line-height: 0; // collapse the wrapper.

	// @max-lines: 2;

  	display: block;
	position: absolute;
	left: @padding-xlarge;
	right: @padding-xlarge;
	bottom: 0;
	width: auto;
	margin-bottom: unit( @line-height-base - 0.2, em );
	// Commenting out the below for now as to save vertical space.
  	// height: @max-lines * @font-size-base * @line-height-medium; // aligns the top of each container and keeps container from overflowing

  	// margin-left: @padding-xlarge * -1px;// compensating for being absolutely positioned
  	.box-sizing(border-box);
  	// overflow: hidden;
  	text-overflow:ellipsis;
  	line-height: 1;

		.readMore-vBottom {
			.readMore();
			display: block;
			margin-bottom: 0.2em;
			line-height: @line-height-smaller;
		  	font-size: @font-size-base;
		  	font-weight: 700;
			color: white;
			&:before{
				line-height: 1.4em;
			}
		}
}

.page-content {
	.clearfix();
}

.row {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	clear: both;
	.clearfix();
	.content {
		float: left;
		width: 85%;
		margin-left: 15%;
		.clearfix();
		&.img-teasers {
			>article {
				width: 25%;
				float: left;
				h3 {
					font-size: 20px;
					margin-bottom: 15px;
				}
				&:nth-child(even) {
					.inner-wrapper {
						background: @sapaRaspberryPink;
					}
				}
				.inner-wrapper {
					background: darken(@sapaRaspberryPink, 7%);
				}
			}
		}
		&.collaborate {
			article {
				.column(4);
				.clearfix();
				h2 {
					font-size: 24px;
					margin-bottom: 15px;
				}
				p {
				}
			}
			.img-links {
				.clearfix();
				.column(8);
				display: table;
				margin-top: 15px;
				a {
					.column(4);
					float: none;
					display: inline-block;
					text-decoration: none;
					text-align: center;
					height: 180px;
					img {
						display: inline-block;
						margin: 0 auto;
						vertical-align: middle;
						width: auto;
						margin-bottom: 30px;
					}
					span {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}

.blankstate {
	background: lighten(@sapaWarmGrey, 40%);
	overflow: hidden;
	padding: 20px;
	margin: 20px;
	.border-radius(2px);
	p {
		margin-bottom: 0;
		color: #555;
		font-style: italic;
	}
}
// table styling
table {

	font-size: @font-size-small;
	line-height: @line-height-small;
	margin-bottom: 1em;
}

// input styling
input[type="text"],
input[type="email"] {
	background: #fff;
	border: 1px solid #e3e3e3;
	.border-radius(2px);
	padding: 10px;
	font-size: 16px;

	&.disabled,
	&[disabled] {
		background-color: #f4f4f4;
		border: 1px solid #ddd;
		cursor: default;
		color: #999;
	}

	&:focus {
		outline: none;
		border-color: #bbb;
	}
}

.faq-module {
    margin-top:10px;
    .faq-question {
        i {
            color:#9d9f9f;
            margin-right:5px;
        }
    }
}