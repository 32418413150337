.ie8 {
	.editor {
		table {
			table-layout: fixed; // Otherwise the tabel overflows its container.
			// tr {
			// 	td {
			// 		vertical-align: top;
			// 	}
			// }
		}
	}
	//Atempting to force rendering on .wide article functionality. Bug: tables get caught in previous state.
	.articleListing.wide {
		zoom:1;
		.filter-col{
			zoom:1;
			> div{
				zoom:1;
				.left-col,
				.right-col{
					zoom:1;
				}
			}
		}
	}
	.flex-caption{
		background-color: @gray-dark;
	}
}
.dtmixin(@param){
  @{param} : table;
}
.ie7 {
    
    [class*=icon-] {
        font-family: "FontAwesome";
        font-style: normal;
    }

	body .filter-list.posts .recent-col ul li{
		display: inline-block;
	}
	body {
		.ol, .ul {
			// display-table-htc replaces some lists as table cells and gives them a class
			list-style: none outside none;
		}
		a *{
			cursor: pointer;
		}
		#header {
			#search {
				.search-wrapper {
					input {
						height: 35px;
						width: 96%; // Round off error in IE 100-3 ≈ 96
						padding-left: 3%;
						padding-right: 0;
					}
				}
			}
		}
		/* Rolling back on previous implementation for nested columns in IE7*/
		.mainContent{
			width: 1144px;
		}
		.start,
		.articleListing{
			width: 1144px;
			td {
				vertical-align: top;
			}
			.left-col {
				// &.filter-col{
				// 	> div{
				// 		// This expands the content vertically to fill the table cell. Works only if the right article is longer than left filters.
				// 		position: absolute;
				// 		left: 0;
				// 		top: 0;
				// 	}
				// }
				&.filter-col{
					position: relative;
					overflow: hidden;
					> div{
						position: absolute;
						top: 0;
						left: 0;
						overflow: hidden;
					}
				}
				&.filter{
					display: block;
					position: absolute;
					left: 0;
				}
				.right-col{
					display: block;
					position: static;
					margin-left: 30%;
					&.filter-list{
						padding-bottom: 4em;
					}
				}
				.bg-cover{
					height: 100%;
				}
			}
		}
		[aria-expanded="true"]{
			&.articleListing{
				.left-col{
					.filter-list.right-col{

						margin-left: 75%;

						.recent-col{
							width: 25%;
							padding-bottom: 0;
						}

						.btn-toggle{
							.bg-cover{
								width: 100%;
							}
						}
					}
				}
			}
		}

		.filter-col > div{
			display: block;
		}
		.filter-col.right-col{
			display: block;
			overflow: hidden;
			.page-content{
				overflow: hidden;
			}
		}
		.right-col{
			display: block;
			float: left !important;
		}

		.left-col{
			display: block;
			position: absolute;
			left: 0;
			float: left;

			#tabs li.active .arrow {
				top: 7px;
			}
			.narrow{
				position: absolute;
				left: 0;
			}
		}

		.inner-wrapper.three-cols{
			padding-left:0;
		}
		.dynamic-boxes{
			li{
				display: inline;
				zoom:1; // IE7 sets hasLayout to true.
				vertical-align: top;
				article{
					.readMore{
						margin-left: 0; // reset space for where the arrow would be
					}
				}
			}
		}
		.filter-col {
			width: 49.9%;
			zoom: 1;
		}
		.filter-col.full-width{
			width: 100%;
		}
		.filter-list .recent-col {

			width: auto; // make the padding visible. From: http://jehiah.cz/a/button-width-in-ie
			overflow: hidden;
            height: auto;

			ul.filter-bar li{
				display: inline;
				zoom: 1;
				.filter-date{
					position: absolute;
					float: none;
					right: 20px;
				}
			}
		}
		.featured-news-carousel{
			padding-right: 0;
			padding-left: 0;

			.flex-control-nav{
				bottom: 15px;
			}
		}
	}
	.faux-bg{
		// background for .start page column
		position: absolute;
		bottom: 0;
		left: 30%;
		width: 70%;
		// height: 999%; // For IE compatibility
		height: 100%;
		background-color: @gray-lightest;
		top:0;
		z-index: -1;
	}
	body #header {
		position: relative;
		//z-index: 3;

		.center {
			z-index: 3;
		}
		.top-bar {
			position: relative;
			//z-index: 5
		}
		#navigation {
			position: relative;
			//z-index: 1
		}
	}
	body .center {
		position: relative;
		z-index: 1;
	}
	body #search-results #circuit-search-result .group .heading {
		width: auto;
	}
	body #search-results #circuit-search-result li{
		.clearfix;
	}
	.editor {
		table {
			table-layout: fixed; // Otherwise the tabel overflows its container.
		}
	}
	.btn-delete .icon-remove{
		line-height: 20px;
	}

	[class^="icon-"],
	[class*=" icon-"] {
	  font-family: FontAwesome;
	  font-weight: normal;
	  font-style: normal;
	  text-decoration: inherit;
	  -webkit-font-smoothing: antialiased;

	/* sprites.less reset */
	  display: inline;
	  width: auto;
	  height: auto;
	  line-height: normal;
	  vertical-align: baseline;
	  background-image: none;
	  background-position: 0% 0%;
	  background-repeat: repeat;
	  margin-top: 0;
	}
	.clicker{
		.icon{
			padding-right: 0;
			line-height: 2.6em;
		}
	}
	.click-nav ul li ul{
		background-color: @gray-lightest;
		border: 1px solid @gray-lighter;
	}
	.icon{
		padding-right: 0.4em;
	}
	.alert{
		zoom:1;
	}

	.flex-caption{
		background-color: @gray-dark;
	}

	.type-user{
		.clearfix();
	}

}