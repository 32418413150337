.accordion-item {
  border-bottom: 1px solid #dbdbdb;
}

.accordion-header {
  position: relative;
  cursor: pointer;
  padding: 30px 0 30px 20px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.accordion-header:hover {
  background: #e2e8ef;
}

.accordion-title {
  margin: 0;
}

.accordion-description {
  margin: 0;
}

.accordion-item .rotate {
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 22px;
  text-align: center;
  color: #456da4;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform-origin: center center;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transition: transform 0.5s ease-in;
  -webkit-transition: transform 0.5s ease-in;
  -ms-transition: transform 0.5s ease-in;
  transform-style: preserve-3d;
}

.accordion-item .rotate.animated {
  transform: translateY(-50%) rotate(135deg);
  -webkit-transform: translateY(-50%) rotate(135deg);
  -ms-transform: translateY(-50%) rotate(135deg);
}

.accordion-content {
  padding: 0 20px 20px;

  label {
    margin: 2rem 0 4px;
    display: block;
    font-weight: 700;
  }

  input[type="text"],
  input[type="number"],
  input[type="file"],
  textarea {
    border: 2px solid #dfdfdf;
    border-radius: 0;
    min-width: 330px;
    padding: 10px;
    font-size: 1rem;
    background: #fff;

    &:focus {
      outline: none;
    }

  }

  select {
    padding: 8px 0;
    border: 2px solid #dfdfdf;
    border-radius: 0;
    font-size: 1rem;
    height: 42px;

    &:focus {
      outline: none;
    }

  }

  button[type="submit"],
  input[type="submit"] {
    display: block;
    margin-top: 2rem;
  }

  .checkbox-wrapper {

    label {
      margin-top: 0;
      display: inline-block;
    }

  }

}
