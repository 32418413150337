
.editor {

	padding: 0 @padding-xlarge @padding-xlarge;

	sub, sup {
	  /* Specified in % so that the sup/sup is the
	     right size relative to the surrounding text */
	  font-size: 75%;

	  /* Zero out the line-height so that it doesn't
	     interfere with the positioning that follows */
	  line-height: 0;

	  /* Where the magic happens: makes all browsers position
	     the sup/sup properly, relative to the surrounding text */
	  position: relative;

	  /* Note that if you're using Eric Meyer's reset.css, this
	     is already set and you can remove this rule */
	  vertical-align: baseline;
	}

	sup {
	  /* Move the superscripted text up */
	  top: -0.5em;
	}

	sub {
	  /* Move the subscripted text down, but only
	     half as far down as the superscript moved up */
	  bottom: -0.25em;
	}
	.text-color-red {
		color: @sapaRed;
	}
	.text-color-green {
		color: @sapaGreen;
	}
	.text-color-yellow {
		color: @sapaMustardYellow;
	}
	.text-color-white {
		color: #ffffff;
	}
	p {
		max-width: 100%;
		a {
			display: inline;
			text-decoration: none;
			color: @experisPrimary;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	a {

		text-decoration: none;
		color: @experisPrimary;

		&:hover {
			text-decoration: underline;
		}
		// &:visited{
		// 	color: #77ADAE; // cadetBlue
		// }

		&.more-link-bold {

		  	.readMoreLink();
			font-weight: bold;
		}
		&.more-link-normal {

  			.readMoreLink();
	  		font-weight: normal;
		}
	}

	img {
		height: auto;
	}
	.alignLeft {
	  	float: left;
	  	margin: 0.2em 20px @margin-bottom-regular 0;
	}
	.alignRight {
	  	float: right;
	  	margin: 0.2em 0 @margin-bottom-regular 20px;
	}
	.has-caption {

		width: auto;
		display:block;

		img {
			margin-bottom: 0.5em;
		}
		span {
			display: block;
			font-size: @font-size-small;
			line-height: unit(@line-height-medium, em);
			letter-spacing: 0.02em;
			text-align: left;
		}
	}
	ul {
		list-style-type: disc;
		margin: 0 0 1em 0;
		li {
			line-height: 22px;
            list-style-type: none;

            &:before {
                content: '\2022';
                position: relative;
                padding-right: 0.5em;
                font-size: 1.5em;
                line-height: 1em;
                top: 4px;
                color: #000000;
            }
		}
		&.arrow-link-list {
			margin-bottom: 0;
			margin-left: 0;
			.icon-chevron-right{
				margin-left: -1em;
				margin-right: 0;
			}
		}
	}
	ol {
		list-style-type: decimal;
		margin: 0 0 1em 0;
        padding-left: 1.5em;
		li {
			line-height: 22px;
		}
	}
	.table-scrollable {
		width: 100%;
		.clearfix();
		overflow-y: hidden;
		overflow-x: auto;
		margin-bottom: @margin-bottom-regular;

		table {
			margin-bottom: 0;
		}
	}

	table {

		border-collapse: collapse;
		// width: 107%; // spill over the right page padding in order to get the table visible without scrolling. -Bad idea. -LR
		// table-layout: fixed; // ensures the table doesn't spill over the container if content is larger than layout

		font-size: @font-size-small;
		line-height: @line-height-small;
		float: none; // This is a fix for a peculiar bug where the table is covered by an empty preceeding paragraph which the editor might add by mistake. -LR

		p{
			font-size: @font-size-small;
		}
		td {
			border: 1px solid @lightGray;
			img {
				max-width: none;
			}
			.has-caption{
				width: 100%;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
		thead {
			font-size: @font-size-base;

			td,
			th {
				overflow: hidden;
				max-width: 16.6%;
				padding: 0.6em 0.4em 0.6em 10px; // The right padding enables the headers to be displayed without being clipped
				vertical-align: top;
				background: @darkGray;
				color: #fff;
				p {
					margin: 0;
				}
			}
			th {
				border: 1px solid @darkGray;
			}
		}
		tbody {
			tr {
				&:nth-child(odd) {
					td {
						background: #f0f0f0;
					}
				}
				td {
					max-width: 16.6%;
					padding: 0.8em 0.6em 1.0em 10px;
					vertical-align: top;
					overflow: hidden;
					text-overflow: ellipsis;
					p {
						margin: 0 0 5px;
						overflow: hidden; // IE7
						text-overflow: ellipsis; // IE7
					}
				}
				th {
					padding: 0.8em 0.6em 1.0em 10px;
					background: @darkGray;
					color: #fff;
					text-align: left;
					vertical-align: top;
				}
			}
		}
		thead,
		tbody {
			tr {
				td,
				th {
					&.background-red {
						background: @sapaRed;
						color: white;
					}
					&.background-green {
						background: @sapaGreen;
						color: white;
					}
					&.background-yellow {
						background: @sapaMustardYellow;
					}
				}
			}
		}
		&.nostyling {
			td {
				border: none;
			}
			thead {
				td,th {
					background: none;
					color: inherit;
				}
			}
			tbody {
				tr {
					td {
						background: none !important;
					}
				}
			}
		}
	}
	blockquote {
	    font-family: Georgia, serif;
	    font-size: 18px;
	    line-height: 26px;
	    color: #6b6b6b;
	    margin: 15px 0;
	    padding-left: 40px;
	    background: url(/Content/images/quotemark.png) no-repeat left top;
	    font-style: italic;
	    display: block;
	}

	// Column styling
	.col {
	  	display: inline-block;
	  	margin: 1.2%;
	  	vertical-align: top;
	  	img {
	  		max-width: 100%;
	  		height: auto;
	  	}
	  	&.one-three {
	  		width: 30%;
	  	}
	  	&.two-three {
	  		width: 62.4%;
	  	}
	  	&.half {
	  		width: 47%;
	  	}
	}
	// Paragraph styling
	p{
		@qa-padding-right: 0.6em;
		&.qa-question{
            position: relative;
            font-size: 26px;
            margin-left: 60px;
			&:before{
                position: absolute;
                left: -60px;
                top: 8px;
                height: 50px;
                width: 50px;
				.font-icon(@fa-var-question);
				font-size: 24px;
                line-height: 50px;
                text-align: center;
				.colorMultifier(background, Primary);
                color: @white;
			}
		}
		&.qa-answer{
			/*font-style: italic;*/
            font-size: 16px;
            margin-bottom: 3em;
			&:before{
                display: none;
//				.font-icon(@fa-var-comment);
//				font-size: @font-size-medium;
//				padding-right: @qa-padding-right - 0.2;
//				color:@mainTurkoiseAlt;
			}

            em {
                font-style: normal;
            }
		}
	}
	// Image styling
	img.border,
	.border img {
		border: 1px solid #000;
	}

	//Justifies or evenly distributes content horizontally within the container. Used among other things for laying out images.
	.align-distributed,
	.align-justified {

		text-align: justify;

		> * {
			.inlineblock();
		}

		// &:after,
		.filler-stretchbar {
			// Insures the preceeding items are justified by creating a line-break.
			content: ""; // ?
			.inlineblock();
			width: 100%;
			height: 0;
		}
		img{
			height: auto;
		}
	}
	.align-distributed{

		.filler {
			display: inline-block;
			*display: inline;
			zoom:1;
			width: 0;
			height:0;
		}
	}

	.img-col-1{ max-width: 50%; }
	.img-col-2{ max-width: 33.333%; }
	.img-col-3{ max-width: 25%; }
	.img-col-4{ max-width: 20%; }
	.img-col-5{ max-width: 16.66666%; }
	.img-col-6{ max-width: 14.285714%; }
	.img-col-7{ max-width: 12.5%; }
	.img-col-8{ max-width: 11.111111%; }

	.img-col-1,
	.img-col-2,
	.img-col-3,
	.img-col-4,
	.img-col-5,
	.img-col-6,
	.img-col-7,
	.img-col-8{
		.box-sizing(border-box);
		vertical-align: top;
	}

    &.video {
        height: 0;
        padding-bottom: 56%;
        position: relative;
        margin: 20px;
        box-sizing: border-box;
        padding-top: 25px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
}

.footnote,
.footnote-border { 
    font-size: 0.786em; //0.786em = 11px, 0.714em = 10px
}

.footnote-border {
    border-top: 1px solid @sapaWarmGrey;
    margin-top: 1.5em;
    padding-top: 0.3em;
}

.footnote .footnote-heading,
.footnote-border .footnote-heading { 
    display: block;
    font-weight: bold;
    color: @sapaBlue;
    text-transform: none;
    font-size: 1.1em;
    margin-bottom: 0.3em;
}