.details {
	display: block;
	float: left;
	clear: both;
	width: 100%;
	padding-top: 1em;
	padding-bottom: 1em;
//	background: #e4e4e4;
	color: @text-gray-dark;
	.box-sizing(border-box);
	// .clearfix();

	&.block {
		z-index: 10;
		width: 30%;
		float: right;
		display: block;
		margin: -20px 0 10px 20px;
		// padding-top: 20px;
		// padding-bottom: 20px;
		padding: 20px 15px;
		.link-list {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			li {
				float: none;
				width: 100%;
				a {
					font-size: 14px;
					text-decoration: none;
					.colorMultifier(color, Primary);
					i {
						font-style: normal;
						color: @experisPrimary;
					}
					&:hover {
                        .colorMultifier(color, Primary75);
					}
				}
			}
		}
		h4 {
			margin-bottom: 18px;
			clear: both;
		}
		.tags {
			margin-bottom: 21px;
			p {
				margin: 0;
			}
		}
	}
	>div {
		float: left;
		margin-right: 20px;
	}
	.tags-wrapper {
		width: 100%;
		padding-bottom: @padding-larger;
	}
	.author {
		width: 100%;
		padding-top: @padding-larger;
		h4 {
			margin-bottom: 0;
			font-size: 14px;
			line-height: 16px;
			a {
				display: inline-block;
				margin-left: 0.5em;
                .colorMultifier(color, Primary);
				text-decoration: none;

                &:hover {
                    .colorMultifier(color, Primary75);
                }
			}
		}
	}
	// i {
	// 	color: #9c9c9c;
	// }
	.tags {
		margin-bottom: 0;
		margin-right: 2px;
		p {
			margin-bottom: 0;
			margin-right: 2px;
		}
	}
	h4 {
		line-height: 18px;
		font-size: 16px;
		display: block;
		margin-bottom: 10px;
		position: relative;
		font-weight: bold;
	}
	.bd {
		font-size: 14px;
		line-height: 20px;
		a {
			text-decoration: none;
			color: #797979;
		}
		p {
			margin-bottom: 0;
		}
	}
}